/**
 * Retry condition
 * @param {Error} error
 * @return {Boolean}
 */
const retry = error =>
  error.response ? error.response.status >= 500 : error.request
/**
 * Delay function
 * @param {Number} ms milliseconds
 * @return {Promise}
 */
const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export const addRetry = (axios, retries = 5, ms = 1e3) =>
  axios.interceptors.response.use(res => res, error => {
    const { config } = error
    if (retry(error)) {
      config._retries = (config._retries || 0) + 1
      if (config._retries <= retries) return delay(ms).then(() => axios(config))
    }
    return Promise.reject(error)
  })

export const handleError = ({ unAuth, unCnx, handle }) =>
  error => {
    const { request, response } = error
    if (response) {
      if (unAuth && response.status === 401) return unAuth(error)
      if (unCnx && response.status >= 500) return unCnx(error)
    } else if (unCnx && request) return unCnx(error)
    handle(error)
  }

export const ajaxError = ctx => handleError({
  unAuth: () => ctx.dispatch('logout', 'Su sesión a expirado', { root: true }),
  unCnx: console.error,
  handle: console.error
})
