import Vue from 'vue'
import Vuex from 'vuex'
import { setSpeedLimit, api as axios } from '@/api'

import router from '@/router'
import { auth, local } from './plugins'
import createWsPlugin from './plugins/ws'

import modules from './modules'

const DELAY_MS = 60e3

Vue.use(Vuex)

const { close: closeWs, plugin: wsPlugin } = createWsPlugin(
  ['ws/updateObject'],
  process.env.VUE_APP_WS,
  { timeout: 5e3, maxAttempts: 3 }
)

const SET_TIMER = (state, timer) => { state.timer = timer }

const update = ctx => {
  ctx.dispatch('data/fetchObjects')
  ctx.dispatch('data/fetchObjectsData')
  ctx.dispatch('data/fetchGeofences')
  ctx.dispatch('data/fetchDrivers')
}

const startUpdate = ctx => {
  if (ctx.state.timer) return
  ctx.commit('SET_TIMER', setInterval(ctx.dispatch, DELAY_MS, 'update'))
  ctx.dispatch('update')
}

const stopUpdate = ctx => {
  clearInterval(ctx.state.timer)
  ctx.commit('SET_TIMER')
}

const logout = (ctx, reason) => {
  closeWs()
  ctx.commit('data/RESET')
  ctx.commit('homeView/RESET')
  ctx.commit('seguimientosView/RESET')
  ctx.commit('referencesView/RESET')
  ctx.commit('reportsView/RESET')
  ctx.dispatch('login/logout')
  if (reason) ctx.commit('login/SET_ERROR', reason)
}

const setSpdLimit = (ctx, params) => setSpeedLimit(params)

const store = new Vuex.Store({
  state: { timer: null },
  mutations: { SET_TIMER },
  actions: { logout, startUpdate, stopUpdate, update, setSpdLimit },
  modules,
  plugins: [auth(router, axios), local('main/map'), wsPlugin]
})

export default store
