import { Marker } from 'maptalks'
import { createSymbol } from '@/maps/vehicle'

export const mapOb = ({ object, name, gps, lastMove }) => ({
  object,
  name,
  date: gps.date,
  speed: gps.speed,
  lat: gps.lat,
  lng: gps.lng,
  t_elapsed: gps.t_elapsed,
  gps_age: gps.gps_age,
  rotation: gps.heading && 360 - gps.heading,
  infoTitle: name,
  ...lastMove ? { lastMove } : null,
  infoContent: `${name}<br>Velocidad: ${gps.speed}km/h<br>
  Dirección: ${gps.dir}`
})

export const withGps = o => o && o.gps

export const createMarker = o => {
  const m = new Marker([o.lng, o.lat], {
    id: o.object,
    symbol: createSymbol(o)
  })
  return m
}
