import arrow from './icons/arrow.png'
import stopArrow from './icons/stopArrow.png'
import warningIcon from './icons/warning.png'
import parking from './icons/parking.svg'
import engineOnIcon from './icons/engineOn.png'
import { MIN_PARKING_TIME, MIN_WARN_TIME, MIN_WARN_TIME_MOV } from '@/util/constants'

const MARKER_SYMBOL = {
  markerHorizontalAlignment: 'middle',
  markerVerticalAlignment: 'middle',
  markerPlacement: 'point'
}

export const createSymbol = ({ lastMove, speed, rotation, t_elapsed: tElapsed, gps_age: gpsAge, engOn }) => {
  const lastMoveDiff = lastMove ? Date.now() / 1000 - lastMove : 0
  const ROTATION = (speed || lastMoveDiff < MIN_PARKING_TIME ? { markerRotation: rotation } : { markerRotation: 0 })
  const marker = Object.assign(
    {},
    MARKER_SYMBOL,
    {
      markerFile: (speed ? arrow
        : lastMoveDiff > MIN_PARKING_TIME ? parking
          : stopArrow),
      markerHeight: 27,
      markerWidth: (speed || lastMoveDiff < MIN_PARKING_TIME ? 20 : 27)
    },
    ROTATION
  )

  const warning = (((tElapsed > MIN_WARN_TIME_MOV && speed) || tElapsed > MIN_WARN_TIME || gpsAge > 240)
    ? {
      markerFile: warningIcon,
      markerHorizontalAlignment: 'left', // left, middle(default), right
      markerVerticalAlignment: 'bottom', // top, middle, bottom(default)
      markerDx: -5,
      markerDy: 4
    }
    : {
      markerFile: null
    }
  )

  const engineOn = ((engOn === true) && (lastMoveDiff > MIN_PARKING_TIME && speed === 0) ? {
    markerFile: engineOnIcon,
    markerHorizontalAlignment: 'right', // left, middle(default), right
    markerVerticalAlignment: 'bottom', // top, middle, bottom(default)
    markerDx: 5,
    markerDy: 3,
    markerFill: 'GREEN',
    markerHeight: 15,
    markerWidth: 21
  }
    : {
      markerFile: null
    })

  return [
    {},
    marker,
    warning,
    engineOn
  ]
}
