const merge = fn => (stt, pay) => Object.assign(stt, fn(stt, pay))
export const updates = fns =>
  Object.keys(fns).reduce((r, k) => (((r[k] = merge(fns[k])), r)), {})

const splitRe = /[ |,]+/
const addKey = (r, k) => (((r[k] = k), r))

export const createTypes = types =>
  (types.split ? types.split(splitRe) : types).reduce(addKey, {})

export const toMap = s =>
  (s.split ? s.split(',') : s).reduce(addKey, {})
