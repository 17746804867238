import { createGeofence, updateGeofence, deleteGeofence } from '@/api'
import { referencesTree } from '@/util/references'

const state = () => ({ drawing: null, drawingName: null, drawingColor: '#2196F3', tree: [], viewed: null })

const SET_DRAWING = (state, drawing) => { state.drawing = drawing }

const SET_DRAWING_COLOR = (state, drawingColor) => { state.drawingColor = drawingColor }

const SET_DRAWING_NAME = (state, drawingName) => { state.drawingName = drawingName }

const getTree = (state, getters, rootState) => referencesTree(rootState.data.referencesList)

const SET_VIEWED = (state, sel) => { state.viewed = sel }

const RESET = old => Object.assign(old, state())

const setDrawing = (ctx, drawing) => ctx.commit('SET_DRAWING', drawing)

const setDrawingColor = (ctx, drawingColor) => ctx.commit('SET_DRAWING_COLOR', drawingColor)

const setDrawingName = (ctx, drawingName) => ctx.commit('SET_DRAWING_NAME', drawingName)

const createRef = (ctx, ref) => createGeofence(ref)

const updateRef = (ctx, { reference, id }) =>
  updateGeofence(reference, id)

const deleteRef = (ctx, id) => deleteGeofence(id)

const getViewed = (state, getters, rootState) =>
  state.viewed && rootState.data.references[state.viewed]

const setViewed = (ctx, sel) => {
  ctx.commit('SET_VIEWED', sel)
  if (sel) ctx.commit('SET_DRAWING_NAME', ctx.rootState.data.references[sel].name)
  else ctx.commit('SET_DRAWING_NAME', '')
}

export default {
  namespaced: true,
  state,
  mutations: { SET_DRAWING, SET_DRAWING_NAME, SET_DRAWING_COLOR, SET_VIEWED, RESET },
  actions: { setDrawing, setDrawingColor, setDrawingName, createRef, updateRef, deleteRef, setViewed },
  getters: { getViewed, getTree }
}
