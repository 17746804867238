import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Seguimientos from './views/Seguimientos.vue'
import References from './views/References.vue'
import ObjectConfig from './views/ObjectConfig'
import Login from './views/Login.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/seguimientos',
      name: 'seguimientos',
      component: Seguimientos
    },
    {
      path: '/reportes',
      name: 'reportes',
      component: () => import(/* webpackChunkName: "reports" */ './views/Reports.vue')
    },
    {
      path: '/referencias',
      name: 'references',
      component: References
    },
    {
      path: '/config/:obj',
      name: 'config',
      component: ObjectConfig
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    }
  ]
})
