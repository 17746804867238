import { Map, control, DomUtil } from 'maptalks'

/**
 * @property {Object} options - options
 * @property {Object} [options.position='bottom-right'] - position of the control
 * @property {Object} [options.baseTitle='Base Layers'] - title of the base layers
 * @property {Object} [options.overlayTitle='Layers'] - title of the overlay layers
 * @property {Object} [options.excludeLayers=[]] - ids of layers that don't display in layerswitcher
 * @property {Object} [options.containerClass=maptalks-layer-switcher] - layerswitcher's container div's CSS class
 *
 * @memberOf control.LayerSwitcher
 * @instance
 */
const options = {
  position: 'bottom-right',
  containerClass: 'maptalks-layer-switcher'
}

/**
 * @classdesc
 * A layerswither control for the map.
 * @category control
 * @extends control.Control
 * @memberOf control
 * @example
 * var layerswither = new Layerswither({
 *     position : {'top': '0', 'right': '0'}
 * }).addTo(map);
 */
class HomeHelpPanel extends control.Control {
  /**
   * method to build DOM of the control
   * @return {HTMLDOMElement}
   */
  buildOn () {
    const container = (this.container = DomUtil.createEl(
      'div',
      this.options.containerClass
    ))
    const panel = (this.panel = DomUtil.createEl('div', 'homeMapPanel'))
    const button = (this.button = DomUtil.createEl('button'))
    const icon = (this.icon = DomUtil.createEl('i', 'ivu-icon ivu-icon-md-help'))

    button.appendChild(icon)
    container.appendChild(button)
    container.appendChild(panel)

    return container
  }

  onAdd () {
    DomUtil.on(this.button, 'mouseover', this._show, this)
    DomUtil.on(this.panel, 'mouseleave', this._hide, this)
    DomUtil.on(this.getMap(), 'click', this._hide, this)
  }

  onRemove () {
    if (this.panel) {
      DomUtil.off(this.button, 'mouseover', this._show, this)
      DomUtil.off(this.panel, 'mouseleave', this._hide, this)
      DomUtil.off(this.getMap(), 'click', this._hide, this)
      DomUtil.removeDomNode(this.panel)
      DomUtil.removeDomNode(this.button)
      DomUtil.removeDomNode(this.icon)
      delete this.panel
      delete this.button
      delete this.icon
      delete this.container
    }
  }

  _show () {
    if (!DomUtil.hasClass(this.container, 'shown')) {
      DomUtil.addClass(this.container, 'shown')
      this._createPanel()
    }
  }

  _hide (e) {
    if (!this.panel.contains(e.toElement || e.relatedTarget)) {
      DomUtil.setClass(this.container, this.options.containerClass)
    }
  }

  _createPanel () {
    this.panel.innerHTML = ''
    this.panel.innerHTML = '<div class="homeMapPanelItem"><img class="homeMapHelpIcon" src="img/arrow.png" alt="Transdat logo">' +
      '<span class="homeMapPanelItemText"> En movimiento</span></div>' +
      '<div class="homeMapPanelItem"><img class="homeMapHelpIcon" src="img/stopArrow.png" alt="Transdat logo">' +
      '<span class="homeMapPanelItemText"> Detenido</span></div>' +
      '<div class="homeMapPanelItem"><img class="homeMapHelpIcon" src="img/parking.png" alt="Transdat logo">' +
      '<span class="homeMapPanelItemText"> Detenido por mas de 10 min.</span></div>' +
      '<div class="homeMapPanelItem"><img class="homeMapHelpIcon" src="img/warning.png" alt="Transdat logo">' +
      '<span class="homeMapPanelItemText"> Sin reporte actual</span></div>'
  }
}

HomeHelpPanel.mergeOptions(options)

Map.mergeOptions({
  layerSwitcherControl: false
})

Map.addOnLoadHook(function () {
  if (this.options.helpPanel) {
    this.helpPanel = new HomeHelpPanel(
      this.options.helpPanel
    )
    this.addControl(this.layerSwitcherControl)
  }
})

export default HomeHelpPanel
