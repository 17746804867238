<template>
  <i-select v-bind="$attrs" v-on="$listeners" filterable :clearable="true"
    @on-query-change="onQuery" @on-clear="onClear">
    <i-option v-for="ref in options" :key="ref.id" :value="ref.id">{{ref.name}}</i-option>
  </i-select>
</template>

<script>
const filter = (q, xs) => xs.filter(x => x && x.name && x.name.toLowerCase().includes(q))

export default {
  data: () => ({ filtered: null }),
  computed: {
    references () { return this.$store.state.data.referencesList || [] },
    options () { return this.filtered || this.references.slice(0, 100) }
  },
  methods: {
    onQuery (query) {
      const q = query.toLowerCase().trim()
      this.filtered = q ? filter(q, this.references).slice(0, 100) : null
    },
    onClear () { this.filtered = null }
  }
}
</script>
