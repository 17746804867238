<template>
  <iHeader class="appHeader">
    <div class="layoutLogo">
      <img class="layoutLogoLeft" src="@/assets/logo-white.svg" alt="Transdat logo">
      <img class="layoutLogoRight" src="@/assets/logo-white-transdat.svg" alt="Transdat logo">
    </div>
    <slot></slot>
    <iMenu mode="horizontal" class="appHeaderMenu" theme="dark" @on-select="menuSelect">
      <template v-if="$route.name !== 'login'" class="layoutNav">
        <iMenuItem to="/" name="mapa" :class="{active: currentView === 'home'}" v-if="user.type !== 21">
          <iIcon size="24" type="md-globe"></iIcon>
          <div class="appHeaderMenuItemText">Mapa</div>
        </iMenuItem>
        <iMenuItem to="/reportes" name="reportes" :class="{active: currentView === 'reportes'}" v-if="user.type !== 21">
          <iIcon size="24" type="ios-paper"></iIcon>
          <div class="appHeaderMenuItemText">Reportes</div>
        </iMenuItem>
        <iMenuItem to="/seguimientos" name="seguimientos" :class="{active: currentView === 'seguimientos'}"
          v-if="user.type !== 21">
          <iIcon size="24" type="md-apps"></iIcon>
          <div class="appHeaderMenuItemText">Seguimientos</div>
        </iMenuItem>
        <iMenuItem to="/referencias" name="references" :class="{active: currentView === 'references'}"
          v-if="user.type !== 21">
          <iIcon size="24" type="md-pin"></iIcon>
          <div class="appHeaderMenuItemText">Referencias</div>
        </iMenuItem>
        <div class="ivu-menu-submenu">
          <div class="ivu-menu-submenu-title">
            <iIcon size="24" type="md-person" />
            {{ $store.state.login.user.name }}
            <i class="ivu-icon ivu-icon-ios-arrow-down ivu-menu-submenu-title-icon"></i>
          </div>
          <div class="submenu-list is-right">
            <div class="submenu-item" v-if="user.type !== 21">
              <a href="https://gestion.transdat.com.ar/" target="_blank">
                Gestion
              </a>
            </div>
            <div class="submenu-item" @click="changePass" v-if="user.type !== 21">Cambiar contraseña</div>
            <div class="submenu-item" @click="tackingModal = true" v-if="user.type !== 21">Dar acceso temporal</div>
            <div class="submenu-item" @click="logout">Salir</div>
          </div>
        </div>
      </template>
    </iMenu>
    <passChangeModal :show.sync="passChangeModal" />
    <tracking-form :show.sync="tackingModal" />
  </iHeader>
</template>

<script>
import PassChangeModal from '@/components/PassChangeModal'
import TrackingForm from './tracking/TrackingForm'

export default {
  data ()
  {
    return {
      passChangeModal: false,
      tackingModal: false
    }
  },
  components: { PassChangeModal, TrackingForm },
  computed: {
    user () { return this.$store.state.login.user },
    currentView () { return this.$route.name }
  },
  methods: {
    changePass () { this.passChangeModal = true },
    logout () { this.$store.dispatch('logout') },
    menuSelect () { }
  }
}
</script>
