import { getObjects, getObjectsData, getGeofences, getDrivers } from '@/api'
import { toObjBy, sort, strComp } from '@/util/util'
import { companiesTree } from '@/util/companies'
import { mapObjs, mapObjsData } from '@/util/objects'
import { updates as mapUpdates } from '@/util/fuex'
import { ajaxError } from '@/util/axiosHelpers'
import Vue from 'vue'

const toObj = toObjBy('object')
const refToObj = toObjBy('id')
const driversToObj = toObjBy('driverId')

const sortByName = sort(strComp('name'))

const state = () => ({
  objects: {},
  objectsData: {},
  objectList: [],
  objectsDataList: [],
  companies: [],
  references: {},
  referencesList: [],
  drivers: {},
  driversList: [],
  alarms: [],
  lastUpd: null
})

const fetchObjects = ctx =>
  getObjects().then(({ data }) => { ctx.commit('SET_OBJECTS', data) }).catch(ajaxError(ctx))

const fetchObjectsData = ctx =>
  getObjectsData().then(({ data }) => { ctx.commit('SET_OBJECTS_DATA', data) }).catch(ajaxError(ctx))

const fetchGeofences = ctx =>
  getGeofences().then(({ data }) => {
    ctx.commit('SET_REFERENCES', data)
  }).catch(ajaxError(ctx))

const fetchDrivers = ctx =>
  getDrivers().then(({ data }) => {
    ctx.commit('SET_DRIVERS', data)
  }).catch(ajaxError(ctx))

const mapObjList = ({ object, name }) => ({ object, name })
const mapRefList = ({ id, name, type, color }) => ({ id, name, type, color })
const mapDriversList = ({ driverId, name }) => ({ driverId, name })

const objsToList = obs => sortByName(obs.map(mapObjList))
const refsToList = refs => sortByName(refs.map(mapRefList))
const driversToList = drivers => sortByName(drivers.map(mapDriversList))
const objsDataToList = obs => sortByName(obs.map(mapObjsData))

const updates = mapUpdates({
  SET_OBJECTS: (state, objects) => ({
    objects: toObj(objects.map(mapObjs)),
    objectList: objsToList(objects),
    companies: companiesTree(objects)
  }),
  SET_OBJECTS_DATA: (state, objects) => ({
    objectsData: toObj(objects.map(mapObjsData)),
    objectsDataList: objsDataToList(objects)
  }),
  SET_OBJECT: (state, object) => ({ objects: { ...state.objects, [object.object]: object } }),
  SET_REFERENCES: (state, references) => ({
    references: refToObj(references),
    referencesList: refsToList(references)
  }),
  SET_DRIVERS: (state, drivers) => ({
    drivers: driversToObj(drivers),
    driversList: driversToList(drivers)
  }),
  RESET: state
})

const UPD_OBJECT = (state, upd) => {
  const id = upd.object
  const old = state.objects[id]
  if (!old) return
  const object = Object.assign({}, old, mapObjs(upd))
  Vue.set(state.objects, id, object)
  state.lastUpd = object
}

const mutations = { ...updates, UPD_OBJECT }

export default {
  namespaced: true,
  state,
  mutations,
  actions: { fetchObjects, fetchObjectsData, fetchGeofences, fetchDrivers }
}
