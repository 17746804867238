import Vue from 'vue'

const state = () => ({
  gral: {},
  res: [],
  resTracks: {},
  resStops: {},
  resTemp: {},
  resFuelLevel: {},
  resEngTemp: {},
  resCan: {},
  resDaily: {},
  resForklift: {},
  resDriver: {},
  resDrivers: {},
  resRoadMachine: {},
  resMaxSpeed: {},
  type: 'tracks',
  object: null,
  driverId: null,
  hash: null,
  lastReportParams: null
})

const SET_REPORT = (state, { gral, type, res, object, driverId }) => {
  // """fix""" for report info
  Vue.set(state.gral, gral.hash, {...state.gral[gral.hash], ...gral})
  Vue.set(state, 'type', type)
  Vue.set(state, 'res', res)
  Vue.set(state, 'object', object || null)
  Vue.set(state, 'driverId', driverId || null)
  Vue.set(state, 'hash', gral.hash)
  const params = {
    type,
    object,
    from: gral.from,
    to: gral.reqTo
  }
  Vue.set(state, 'lastReportParams', params)
}

const SET_TRACKS = (state, { res, hash }) => { Vue.set(state.resTracks, hash, res) }
const SET_STOPS = (state, { res, hash }) => { Vue.set(state.resStops, hash, res) }
const SET_TEMP = (state, { res, hash }) => { Vue.set(state.resTemp, hash, res) }
const SET_FUEL_LEVEL = (state, { res, hash }) => { Vue.set(state.resFuelLevel, hash, res) }
const SET_ENG_TEMP = (state, { res, hash }) => { Vue.set(state.resEngTemp, hash, res) }
const SET_CAN = (state, { res, hash }) => { Vue.set(state.resCan, hash, res) }
const SET_DAILY = (state, { res, hash }) => { Vue.set(state.resDaily, hash, res) }
const SET_FORKLIFT = (state, { res, hash }) => { Vue.set(state.resForklift, hash, res) }
const SET_DRIVER = (state, { res, hash }) => { Vue.set(state.resDriver, hash, res) }
const SET_DRIVERS = (state, { res, hash }) => { Vue.set(state.resDrivers, hash, res) }
const SET_OBJECT = (state, o) => { state.object = o }
const SET_DRIVER_ID = (state, id) => { state.driverId = id }
const SET_HASH = (state, hash) => { state.hash = hash }
const SET_ROAD_MACHINE = (state, { res, hash }) => { Vue.set(state.resRoadMachine, hash, res) }
const SET_MAX_SPEED = (state, { res, hash }) => { Vue.set(state.resMaxSpeed, hash, res) }

const RESET = old => Object.assign(old, state())

const setReport = (ctx, report) => ctx.commit('SET_REPORT', report)
const setTracks = (ctx, tracks) => ctx.commit('SET_TRACKS', tracks)
const setStops = (ctx, stops) => ctx.commit('SET_STOPS', stops)
const setTemp = (ctx, vals) => ctx.commit('SET_TEMP', vals)
const setEngTemp = (ctx, vals) => ctx.commit('SET_ENG_TEMP', vals)
const setFuelLevel = (ctx, vals) => ctx.commit('SET_FUEL_LEVEL', vals)
const setCan = (ctx, vals) => ctx.commit('SET_CAN', vals)
const setDaily = (ctx, vals) => ctx.commit('SET_DAILY', vals)
const setForklift = (ctx, vals) => ctx.commit('SET_FORKLIFT', vals)
const setDriver = (ctx, vals) => ctx.commit('SET_DRIVER', vals)
const setDrivers = (ctx, vals) => ctx.commit('SET_DRIVERS', vals)
const setObject = (ctx, o) => ctx.commit('SET_OBJECT', o)
const setDriverId = (ctx, id) => ctx.commit('SET_DRIVER_ID', id)
const setHash = (ctx, hash) => ctx.commit('SET_HASH', hash)
const setRoadMachine = (ctx, vals) => ctx.commit('SET_ROAD_MACHINE', vals)
const setMaxSpeed = (ctx, vals) => ctx.commit('SET_MAX_SPEED', vals)

export default {
  namespaced: true,
  state,
  actions: { setReport, setTracks, setStops, setTemp, setFuelLevel, setObject, setEngTemp, setHash, setCan, setDaily, setForklift, setDriver, setDrivers, setDriverId, setRoadMachine, setMaxSpeed },
  mutations: { SET_REPORT, RESET, SET_STOPS, SET_TRACKS, SET_TEMP, SET_FUEL_LEVEL, SET_OBJECT, SET_ENG_TEMP, SET_HASH, SET_CAN, SET_DAILY, SET_FORKLIFT, SET_DRIVER, SET_DRIVERS, SET_DRIVER_ID, SET_ROAD_MACHINE, SET_MAX_SPEED }
}
