import Vue from 'vue'

const state = () => ({
  vieweds: new Array(8).fill(null),
  mapQuant: 2
})

const SET_VIEWED = (state, { map, object }) => Vue.set(state.vieweds, map, object)

const SET_MAP_QUANT = (state, q) => { state.mapQuant = q }

const RESET = old => Object.assign(old, state())

const getViewed = (state, getters, rootState) => map =>
  state.vieweds[map] && rootState.data.objects[state.vieweds[map]]

const setMapQuant = (ctx, q) => { ctx.commit('SET_MAP_QUANT', q) }

const setViewed = (ctx, { map, object }) => {
  if (map != null) ctx.commit('SET_VIEWED', { map, object })
}

export default {
  namespaced: true,
  state,
  actions: { setViewed, setMapQuant },
  getters: { getViewed },
  mutations: { SET_VIEWED, SET_MAP_QUANT, RESET }
}
