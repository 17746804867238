import { Marker, Circle, GeoJSON } from 'maptalks'
import { invTextSymbol } from './mapUtils'
import { createSymbol } from '../vehicle'

export const createMarker = () => o => {
  const m = new Marker([o.lng, o.lat], { id: o.object, symbol: createSymbol(o) })
  m.setInfoWindow({ title: o.name, content: o.infoContent })
  m.on('mouseenter', () => m.openInfoWindow())
  m.on('mouseout', () => m.closeInfoWindow())
  return m
}

export const createPointSymbol = p => {
  return [
    {
      markerType: 'pin',
      markerFill: '#' + p.color,
      markerFillOpacity: 1,
      markerLineColor: '#FFF',
      markerLineWidth: 1,
      markerLineOpacity: 1,
      markerWidth: 30,
      markerHeight: 30,
      markerDx: 0,
      markerDy: -5,
      markerOpacity: 0.5
    },
    {
      textName: p.name,
      ...invTextSymbol,
      textVerticalAlignment: 'bottom'
    }
  ]
}

export const createPolygonSymbol = p => {
  return [
    {
      polygonFill: '#' + p.color,
      polygonOpacity: 0.2,
      lineColor: '#' + p.color,
      lineWidth: 2,
      lineJoin: 'round',
      lineCap: 'round',
      lineOpacity: 0.7,
      lineDasharray: [20, 10]
    },
    {
      textName: p.name,
      ...invTextSymbol
    }
  ]
}

export const createRefMarker = () => r => {
  const m = GeoJSON.toGeometry(r.geometry)
  m.setId(r.id)
  m.setInfoWindow({ title: r.name })
  if (r.type === 1) m.setSymbol(createPointSymbol(r))
  else m.setSymbol(createPolygonSymbol(r))
  return m
}

export const createRefCircle = () => r => {
  const m = new Circle(r.geometry.coordinates, r.radius, {
    symbol: {
      polygonFill: '#' + r.color,
      polygonOpacity: 0.4,
      lineColor: '#' + r.color,
      lineWidth: 2,
      lineOpacity: 0.7,
      lineDasharray: [20, 10]
    }
  })
  m.setId(r.id)

  return m
}

export const updateRefMarker = (marker, r) => {
  marker.setCoordinates(r.geometry.coordinates)
  if (r.type === 1) {
    marker.updateSymbol(createPointSymbol(r))
  } else {
    marker.updateSymbol(createPolygonSymbol(r))
  }
}

export const updateCircleMarker = (marker, r) => {
  marker.setRadius(r.radius)
  marker.setCoordinates(r.geometry.coordinates).updateSymbol({
    polygonFill: '#' + r.color,
    polygonOpacity: 0.2,
    lineColor: '#' + r.color,
    lineWidth: 2,
    lineOpacity: 0.7,
    lineDasharray: [20, 10]
  })
}
