<template>
  <div class="homeSider">
    <references-select class="homeTreeSelect" :value="reference" @input="select" />
    <VScroll :rows="rows" :value="value" :height="44">
      <div slot-scope="row" v-bind="row" :class="row.class" @click="select(row.id)">
        <iAvatar :style="'color: ' + row.color + ';background-color: rgb(96.1%, 96.9%, 97.6%)'" :icon="row.icon" />
        {{row.label}}
      </div>
    </VScroll>
  </div>
</template>

<script>
import VScroll from '../VScroll'
import ReferencesSelect from './ReferencesSelect'

const isNum = n => typeof n === 'number'
const fItem = sel => i => ({
  id: i.id,
  label: i.name,
  class: 'referencesTreeItem' + (sel && sel.id === i.id ? ' is-active' : ''),
  icon: i.type === 1 ? 'md-pin' : 'md-square-outline',
  color: '#' + i.color
})

export default {
  components: { VScroll, ReferencesSelect },
  computed: {
    items () { return this.$store.getters['referencesView/getTree'] },
    sel () {
      return this.$store.getters['referencesView/getViewed']
    },
    reference () { return this.$store.state.referencesView.viewed },
    rows () { return this.items.map(fItem(this.sel)) },
    value () { return this.items.findIndex(i => this.sel && i.id === this.sel.id) }
  },
  methods: {
    select (id) { isNum(id) && this.$store.dispatch('referencesView/setViewed', id) }
  }
}
</script>
