import BaseMap from './baseMap'
import HomeHelpPanel from './homeMapHelpPanel'
import { createGeometries, createVectorLayers } from './utils/homeMapFactory'
import { mapOb, createMarker, createLabelMarker, createRefMarker, createRefCircle } from '@/maps/utils/homeMapUtils'
import { updateCircleMarker, updateRefMarker, updateMarker } from '@/maps/utils/homeMapUpdater'
import { mapAnimateTo, fObjs, fRefsRadius, fRefs } from '@/maps/utils/mapUtils'

const GREEN_DARKER = 'hsl(141, 71%,  40%)'

const HomeMap = ($el, objects, references, baseLayerId, onSetBaseLayer, noClusters) => {
  objects = fObjs(objects)
  let refPointsRadius = fRefsRadius(references)
  references = fRefs(references)

  let selObjHandler = null
  const onClick = ev => {
    ev.domEvent && ev.domEvent.stopPropagation()
    if (!selObjHandler) return
    const { target } = ev
    selObjHandler(target.getId())
  }

  const { markers, labelMarkers, refsMarkers, refsMarkersPointsRadius } = createGeometries(objects, references, refPointsRadius, onClick)

  const { refsPointsRadiusLayer, referencesCluster, labelsCluster, objectsCluster } = createVectorLayers(markers, labelMarkers, refsMarkers, refsMarkersPointsRadius, noClusters)

  const { map, destroy } = BaseMap($el, [refsPointsRadiusLayer, referencesCluster, labelsCluster, objectsCluster], ['Referencias', 'Radio Puntos', 'Unidades', 'Labels'])

  const helpPanel = new HomeHelpPanel({})
  map.addControl(helpPanel)

  // set baselayer if default one wasn't the last selected
  if (baseLayerId) {
    const baseLayer = map.getBaseLayer()
    const baseLayers = baseLayer.layers
    if (baseLayers) {
      for (let i = 0, len = baseLayers.length; i < len; i++) {
        const _baseLayer = baseLayers[i]
        _baseLayer[_baseLayer._id === baseLayerId ? 'show' : 'hide']()
      }
    } else if (!baseLayer.isVisible()) {
      baseLayer.show()
    }
  }

  map.on('click', () => selObjHandler && selObjHandler())
  map.on('click', (e) => {
    const cluster = objectsCluster.identify(e.coordinate)
    if (cluster.children) map.animateTo({ zoom: map.getZoom() + 2, center: e.coordinate })
  })

  map.on('setbaselayer', (e) => {
    const baseLayer = e.target.getBaseLayer().layers.filter(layer => layer.isVisible())[0]._id

    onSetBaseLayer(baseLayer)
  })

  const update = obj => {
    if (!obj || !obj.gps) return
    obj = mapOb(obj)
    const id = obj.object
    const old = objects[id]
    if (!old) return
    obj.name = old.name
    updateMarker(markers[id], labelMarkers[id], obj, old)
  }

  const updateAll = objs => {
    const news = fObjs(objs)
    // remove markers
    for (const k in objects) !news[k] && markers[k] && markers[k].remove()
    for (const k in news) {
      if (objects[k]) {
        updateMarker(markers[k], labelMarkers[k], news[k], objects[k])
      } else {
        const m = createMarker(onClick)(news[k])
        const lm = createLabelMarker()(news[k])
        markers[k] = m
        labelMarkers[k] = lm
        objectsCluster.addGeometry(m)
        labelsCluster.addGeometry(lm)
      }
    }
    objects = news
  }

  const updateReferences = refs => {
    const newRefs = fRefs(refs)
    const newCircles = fRefsRadius(refs)
    // remove markers
    for (const k in references) !newRefs[k] && refsMarkers[k] && refsMarkers[k].remove()
    for (const k in newRefs) {
      if (references[k]) {
        updateRefMarker(refsMarkers[k], newRefs[k])
      } else {
        const m = createRefMarker(onClick)(newRefs[k])
        refsMarkers[k] = m
        referencesCluster.addGeometry(m)
      }
    }
    references = newRefs

    for (const k in refPointsRadius) !newCircles[k] && refsMarkersPointsRadius[k] && refsMarkersPointsRadius[k].remove()
    for (const k in newCircles) {
      if (refPointsRadius[k]) {
        updateCircleMarker(refsMarkersPointsRadius[k], newCircles[k])
      } else {
        const m = createRefCircle()(newCircles[k])
        refsMarkersPointsRadius[k] = m
        refsPointsRadiusLayer.addGeometry(m)
      }
    }
    refPointsRadius = newCircles
  }

  const setViewed = (sel, old) => {
    if (sel) {
      markers[sel.object].updateSymbol([{
        markerType: 'ellipse',
        markerFill: GREEN_DARKER,
        markerFillOpacity: 1,
        markerLineColor: GREEN_DARKER,
        markerLineWidth: 1,
        markerLineOpacity: 1,
        markerWidth: 34,
        markerHeight: 34,
        markerOpacity: 0.7
      }, {}, {}])

      markers[sel.object].bringToFront()
    }

    if (old && (!sel || sel.object !== old.object)) markers[old.object].updateSymbol([{ markerType: null }, {}, {}])
  }

  const animateTo = (value) => mapAnimateTo(map, value)

  const onSelect = cb => { selObjHandler = cb }

  return { map, onSelect, update, updateAll, destroy, animateTo, updateReferences, setViewed }
}

export default HomeMap
