import { createLabelSymbol, invTextSymbol } from './mapUtils'
import { Marker, GeoJSON, Circle } from 'maptalks'
import { createSymbol } from '../vehicle'
import { shortFmt, fmtDistanceToNow } from '@/util/dates'

export const mapOb = ({ object, name, gps, lastMove, engOn }) => ({
  object,
  ...name ? { name } : null,
  date: gps.date,
  speed: gps.speed,
  lat: gps.lat,
  lng: gps.lng,
  lastMove,
  t_elapsed: gps.t_elapsed,
  gps_age: gps.gps_age,
  engOn: engOn,
  rotation: gps.heading && (360 - gps.heading),
  infoContent: infoContent(gps, lastMove)
})

const infoContent = (gps, lastMove) => {
  if (lastMove) {
    return `
  <b>Ult. Reporte:</b> ${shortFmt(gps.date)} (hace ${fmtDistanceToNow(gps.date)})<br>
  <b>Ult. Movimiento:</b> ${shortFmt(lastMove)} (hace ${fmtDistanceToNow(lastMove)})<br>
  <b>Velocidad:</b> ${parseInt(gps.speed)} km/h<br>
  <b>Dirección:</b> ${gps.dir}`
  }

  return `
  <b>Ult. Reporte:</b> ${shortFmt(gps.date)} (hace ${fmtDistanceToNow(gps.date)})<br>
  <b>Velocidad:</b> ${parseInt(gps.speed)} km/h<br>
  <b>Dirección:</b> ${gps.dir}`
}

export const mapRef = (r) => ({
  id: r.id,
  name: r.name,
  geometry: {
    coordinates: Array.from(r.geom.coordinates),
    type: r.geom.type
  },
  radius: (r.radius ? r.radius : null),
  type: r.type,
  color: r.color
})

export const mapRefsPoints = (r) => ({
  id: r.id,
  name: r.name,
  geometry: {
    coordinates: Array.from(r.geom.coordinates),
    type: 'Circle'
  },
  type: r.type,
  radius: r.radius,
  color: r.color
})

export const createMarker = onClick => o => {
  const m = new Marker([o.lng, o.lat], { id: o.object, symbol: createSymbol(o) })
  m.setInfoWindow({ title: o.name, content: o.infoContent })
  m.on('mouseenter', () => m.openInfoWindow())
  m.on('mouseout', () => m.closeInfoWindow())
  m.on('click', onClick)
  return m
}

export const createLabelMarker = () => o => {
  const m = new Marker([o.lng, o.lat],
    {
      id: o.object,
      interactive: false,
      cursor: 'pointer',
      symbol: createLabelSymbol(o)
    })
  return m
}

export const createRefMarker = (onClick) => r => {
  const m = GeoJSON.toGeometry(r.geometry)
  m.setId(r.id)
  if (r.type === 1) {
    m.setSymbol([
      {
        markerType: 'pin',
        markerFill: '#' + r.color,
        markerFillOpacity: 1,
        markerLineColor: '#FFF',
        markerLineWidth: 1,
        markerLineOpacity: 1,
        markerWidth: 30,
        markerHeight: 30,
        markerDx: 0,
        markerDy: -5,
        markerOpacity: 1
      },
      {
        textName: r.name,
        ...invTextSymbol,
        textVerticalAlignment: 'bottom'
      }
    ])
  } else {
    m.setSymbol([
      {
        polygonFill: '#' + r.color,
        polygonOpacity: 0.4,
        lineColor: '#' + r.color,
        lineWidth: 2,
        lineJoin: 'round',
        lineCap: 'round',
        lineOpacity: 0.4
      },
      {
        textName: r.name,
        ...invTextSymbol
      }
    ])
  }
  return m
}

export const createRefCircle = () => r => {
  const m = new Circle(r.geometry.coordinates, r.radius, {
    symbol: {
      polygonFill: '#' + r.color,
      polygonOpacity: 0.4,
      lineColor: '#' + r.color,
      lineWidth: 2,
      lineOpacity: 0.4
    }
  })
  m.setId(r.id)

  return m
}
