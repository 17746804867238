import Sockette from 'sockette'

const watchOps = { deep: true, inmediate: true }

const jsonOr = (x, or) => {
  try { return JSON.parse(x) } catch (e) { return or }
}

const defaultUrl = () => location.protocol.replace('http', 'ws') + location.host

const createWsPlugin = (actionList = [], url, ops = {}) => {
  if (actionList.split) actionList = actionList.split(',')
  const actions = new Set(actionList)
  if (!url) url = defaultUrl()
  let ws

  const close = () => ws && ws.close(1000)

  const plugin = store => {
    const fnWatcher = state => state.login.token
    const getToken = () => store.state.login && store.state.login.token
    const onmessage = ({ data }) => {
      const { type, payload } = jsonOr(data, {})
      actions.has(type) && store.dispatch(type, payload)
    }
    const onmaximum = e => setTimeout(() => createWs(getToken()), 30e3)
    const createWs = token => {
      ws && ws.close(1000)
      if (!token) return
      const protocols = ['acces_token', token]
      ws = new Sockette(url, { ...ops, onmessage, onmaximum, protocols })
    }
    createWs(getToken())
    store.watch(fnWatcher, createWs, watchOps)
  }

  return { close, plugin }
}
export default createWsPlugin
