<template>
  <div>
    <appHeader/>
    <div class="objectConfigContainer">
      <iCard class="objectConfigCard">
        <p slot="title">Configuracion {{ sel.name }}</p>
        <div>
          <b>Límite de velocidad</b> <iInput class="objectConfigCardInput" v-model="speedLimit" type="number"></iInput> Km/h
        </div>
        <br>
        <div>Si la velocidad del vehiculo supera este límite se activará el buzzer en caso de estar instalado, si lo hace por más de 1 minuto se generará una alarma en caso de estar configurada (email, telegram)</div>
        <br>
        <div class="footer">
          <iButton to="/">Cancelar</iButton>
          <iButton @click="onOk()">Aceptar</iButton>
        </div>
      </iCard>
    </div>
    <iModal
        v-model="confirmModal"
        title="Confirmar configuración"
        @on-ok="onConfirm">
        <div>
          Se va a configurar el siguiente valor para la unidad {{sel.name}}<br>
        Límite de velocidad: {{speedLimit}} Km/h</div>
    </iModal>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'

export default {
  components: { AppHeader },
  computed: {
    sel () {
      return this.$store.state.data.objects[this.$route.params.obj]
    }
  },
  data () {
    return {
      confirmModal: false,
      speedLimit: null
    }
  },
  methods: {
    onOk () {
      this.confirmModal = true
    },
    onConfirm () {
      this.confirmModal = false
      this.$store.dispatch('setSpdLimit', { object: this.sel.object, limit: this.speedLimit })
        .then(() => {
          this.$Modal.success({
            title: 'Limite de Velocidad configurado',
            content: 'Limite de Velocidad configurado correctamente. En breve se verá el cambio en la información del vehículo.',
            onOk: () => this.$router.push('/')
          })
        })
    }
  },
  mounted () {
    this.speedLimit = this.sel.speedLimit
  }
}
</script>

<style>

</style>
