import { createSymbol } from '@/maps/vehicle'
import { getOffset, invTextSymbol } from '@/maps/utils/mapUtils'

export const updateMarker = (marker, labelMarker, obj, old) => {
  if (obj.date <= old.date) return

  const offset = getOffset(marker.getCoordinates(), [obj.lng, obj.lat])
  if (offset.x === 0 && offset.y === 0) return

  if (marker._animPlayer) {
    marker._animPlayer.finish()
  }
  marker.setInfoWindow({ title: obj.name, content: obj.infoContent })
  labelMarker.hide()
  labelMarker.setCoordinates([obj.lng, obj.lat])
  marker.animate(
    {
      translate: offset
    },
    {
      duration: 2e3
    },
    frame => {
      if (frame.state.playState === 'finished') {
        marker
          .setCoordinates([obj.lng, obj.lat])
          .updateSymbol(createSymbol(obj))
        labelMarker.show()
      }
    }
  )
}

export const updateRefMarker = (marker, r) => {
  marker.setCoordinates(r.geometry.coordinates)
  if (r.type === 1) {
    marker.updateSymbol([
      {
        markerType: 'pin',
        markerFill: '#' + r.color,
        markerFillOpacity: 1,
        markerLineColor: '#FFF',
        markerLineWidth: 1,
        markerLineOpacity: 1,
        markerLineDasharray: [],
        markerWidth: 30,
        markerHeight: 30,
        markerDy: -5,
        markerOpacity: 1
      },
      {
        textName: r.name,
        ...invTextSymbol,
        textVerticalAlignment: 'bottom'
      }
    ])
  } else {
    marker.updateSymbol([
      {
        polygonFill: '#' + r.color,
        polygonOpacity: 0.4,
        lineColor: '#' + r.color,
        lineWidth: 2,
        lineJoin: 'round',
        lineCap: 'round',
        lineOpacity: 0.4
      },
      {
        textName: r.name,
        ...invTextSymbol
      }
    ])
  }
}

export const updateCircleMarker = (marker, r) => {
  marker.setCoordinates(r.geometry.coordinates).updateSymbol({
    polygonFill: '#' + r.color,
    polygonOpacity: 0.4,
    lineColor: '#' + r.color,
    lineWidth: 2,
    lineOpacity: 0.4
  })
}
