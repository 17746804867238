<template>
  <div class="loginLayout" @keyup.enter="handleSubmit">
    <app-header />
      <iRow class="loginLayoutContent" type="flex" justify="center" align="middle">
        <iCol span="6">
          <iForm ref="form" :model="formInline" :rules="ruleInline">
            <iFormItem prop="user">
              <iInput v-model="formInline.username" type="text" placeholder="Usuario" size="large" :autofocus="true">
                <iIcon slot="prepend" type="ios-person-outline" />
              </iInput>
            </iFormItem>
            <iFormItem prop="password">
              <iInput v-model="formInline.password" type="password" placeholder="Contraseña" size="large">
                <iIcon slot="prepend" type="ios-lock-outline" />
              </iInput>
            </iFormItem>
            <iFormItem>
              <iButton type="primary" @click="handleSubmit">Iniciar sesion</iButton>
            </iFormItem>
          </iForm>
          <div v-if="error" class="ivu-alert ivu-alert-error">
            {{errorMsg}}
          </div>
        </iCol>
      </iRow>
      <div class="loginLayoutCopy">2018 &copy; Transdat</div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'

export default {
  components: { AppHeader },
  data () {
    return {
      formInline: {
        username: '',
        password: ''
      },
      ruleInline: {
        username: { required: true, message: 'Requerido', trigger: 'blur' },
        password: { required: true, message: 'Requerido', trigger: 'blur' }
      }
    }
  },
  computed: {
    error () { return this.$store.state.login.error },
    errorMsg () {
      return this.error.response && this.error.response.data.uiMsg ? this.error.response.data.uiMsg : 'Su sesión expiró, ingrese nuevamente.'
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$store.dispatch('login/login', {
            pass: this.formInline.password,
            user: this.formInline.username
          })
          this.$refs.form.resetFields()
        } else {
          this.$refs.form.resetFields()
        }
      })
    }
  }
}
</script>

<style>
</style>
