<template>
  <i-modal v-model="showModal" title="Dar acceso temporal">
    <i-form :model="model" :rules="rules" ref="form" v-if="!url">
      <i-form-item label="Unidad" prop="object">
        <object-select v-model="model.object"/>
      </i-form-item>
      <i-form-item label="Desde" prop="range">
        <i-date-picker v-model="model.range[0]" format="dd/MM/yy HH:mm"
          type="datetime"/>
      </i-form-item>
      <i-form-item label="Hasta" prop="range">
        <i-date-picker v-model="model.range[1]" format="dd/MM/yy HH:mm"
          type="datetime"/>
      </i-form-item>
      <i-form-item label="Chofer" prop="driverId">
        <driverSelect v-model="model.driverId"/>
      </i-form-item>
    </i-form>
    <i-alert type="success" show-icon v-if="url">
      {{objName}}
      <template slot="desc">
        <div>
          <i class="ivu-icon ivu-icon-md-link"></i>
          <a :href="url" target="_blank">Link para compartir</a>
        </div>
        <div>
          <i class="ivu-icon ivu-icon-logo-whatsapp"></i>
          <a :href="wsppUrl" target="_blank" >Compartir por Whatsapp web</a>
        </div>
      </template>
    </i-alert>
    <template slot="footer">
      <i-button type="text" size="large" @click.native="cancel">Cancelar</i-button>
      <i-button type="primary" size="large" @click.native="submit">Aceptar</i-button>
    </template>
  </i-modal>
</template>

<script>
import DriverSelect from '@/components/DriverSelect'
import { getTracking } from '@/api'
import ObjectSelect from '../ObjectSelect'
import { startOfToday, startOfTomorrow } from '@/util/dates'
import Confirm from './ConfirmTracking'

export default {
  name: 'TrackingForm',
  components: { ObjectSelect, DriverSelect },
  props: { show: Boolean },
  data () {
    return {
      model: {
        object: null,
        range: [startOfToday(), startOfTomorrow()],
        driverId: null
      },
      rules: {
        object: { required: true, type: 'integer', message: 'Por favor elija una unidad', trigger: 'change' }
      },
      url: null
    }
  },
  computed: {
    showModal: {
      get () { return this.show },
      set (v) { this.$emit('update:show', v) }
    },
    objName () {
      return this.model.object && this.$store.state.data.objects[this.model.object].name
    },
    driverName () {
      return this.model.driverId && this.$store.state.data.drivers[this.model.driverId].name
    },
    wsppUrl () {
      return `https://web.whatsapp.com/send?text=${encodeURIComponent(this.url)}`
    }
  },
  methods: {
    async submit () {
      if (this.url) return this.cancel()
      const isValid = await this.$refs.form.validate()
      if (!isValid) return
      const { object, range: [from, to], driverId } = this.model
      const c = await Confirm(this, { from, to, object: this.objName, driver: this.driverName })
      if (!c) return
      const { data } = await getTracking({ object, from, to, driverId })
      this.url = data.url
    },
    cancel () {
      this.model.object = null
      this.url = null
      this.showModal = false
    }
  }
}

</script>
