import { addYears, isDate, isValid, isWithinInterval, format, startOfMonth, startOfToday, startOfTomorrow, startOfYesterday, subDays, differenceInCalendarDays, parse, parseISO, toDate, formatDistanceToNow } from 'date-fns'
import es from 'date-fns/locale/es'

const oneDay = 86400
const oneHour = 3600
const oneMin = 60
const locale = { locale: es }

export const duration = s =>
{
  let r = ''
  if (s < 60) return `${s}s`
  r += ((s > oneDay) ? `${(s / oneDay | 0)}d ` : '')
  const h = ((s % oneDay) / oneHour | 0)
  if (h) r += h + 'h '
  const m = ((s % oneHour) / oneMin | 0)
  if (m) r += m + 'min '
  const sec = (s % oneMin)
  if (sec) r += sec + 's'
  return r
}

export const durationWoUnits = s =>
{
  if (s < 60) return { seconds: s }
  return {
    days: ((s > oneDay) ? (s / oneDay | 0) : 0),
    hours: ((s % oneDay) / oneHour | 0),
    minutes: ((s % oneHour) / oneMin | 0),
    seconds: (s % oneMin)
  }
}

export const fromUnix = s => new Date(s * 1e3)

export const disabledDateRange = () =>
{
  const start = startOfMonth(addYears(startOfToday(), -1))
  const end = startOfTomorrow()
  return d => isWithinInterval(parse(d, 'dd/MM/yy HH:mm', new Date()), { start: start, end: end })
}

export const diff10days = ([from, to]) => differenceInCalendarDays(to, from) > 10
export const diffDaysLessThan = ([from, to], days) =>
{
  from = typeof from === 'string' ? parseISO(from) :
    typeof from === 'number' ? fromUnix(from) : from
  to = typeof to === 'string' ? parseISO(to) :
    typeof to === 'number' ? fromUnix(to) : to
  differenceInCalendarDays(to, from) > days
}

export const stdFormat = d =>
{
  if (typeof d === 'string') return format(parseISO(d), 'dd/MM/yy HH:mm:ss')
  else if (typeof d === 'number') return format(fromUnix(d), 'dd/MM/yy HH:mm:ss')
  else if (typeof d === 'object') return format(d, 'dd/MM HH:mm')
}

export const stdDateFormat = d =>
{
  if (typeof d === 'string') return format(parseISO(d), 'dd/MM/yy')
  else if (typeof d === 'number') return format(fromUnix(d), 'dd/MM/yy')
  else if (typeof d === 'object') return format(d, 'dd/MM/yy')
}

export const shortFmt = d =>
{
  if (typeof d === 'string') return format(parseISO(d), 'dd/MM/yy HH:mm')
  else if (typeof d === 'number') return format(fromUnix(d), 'dd/MM/yy HH:mm')
  else if (typeof d === 'object') return format(d, 'dd/MM HH:mm')
}

export const shortFmtWoYear = d =>
{
  if (typeof d === 'string') return format(parseISO(d), 'dd/MM HH:mm')
  else if (typeof d === 'number') return format(fromUnix(d), 'dd/MM HH:mm')
  else if (typeof d === 'object') return format(d, 'dd/MM HH:mm')
}

export const wFormat = d =>
{
  if (typeof d === 'string') return format(parseISO(d), 'dd/MMM/yy HH:mm', locale)
  else if (typeof d === 'number') return format(fromUnix(d), 'dd/MMM/yy HH:mm', locale)
  else if (typeof d === 'object') return format(d, 'dd/MM HH:mm')
}

export const fmtDistanceToNow = d =>
{
  if (typeof d === 'string') return formatDistanceToNow(parseISO(d), locale)
  else if (typeof d === 'number') return formatDistanceToNow(fromUnix(d), locale)
  else if (typeof d === 'object') return formatDistanceToNow(d, locale)
}

export const weekAgo = () => subDays(startOfToday(), 7)

export { isDate, startOfToday, startOfTomorrow, startOfYesterday, format, parseISO, differenceInCalendarDays, isValid, toDate }
