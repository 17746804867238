<template>
  <p class="homeMapFooterCardItem">
    <iIcon :type="icon" size="20"/>
    <b> {{ label }}:</b> {{ value }} {{ unit }}
  </p>
</template>

<script>
export default {
  props: {
    icon: String,
    label: String,
    value: [String, Number],
    unit: String
  }
}
</script>
