import { VectorLayer, DrawTool } from 'maptalks'
import {
  createRefCircle,
  createRefMarker,
  updateCircleMarker,
  updateRefMarker,
  createMarker
} from './utils/referencesUtils'
import { invTextSymbol, fObjs } from './utils/mapUtils'
import BaseMap from './baseMap'
import { mapO, toObjBy } from '@/util/util'
import { createLabelMarker } from '@/maps/utils/homeMapUtils'
import { ClusterLayer } from 'maptalks.markercluster'

const mapRef = r => ({
  id: r.id,
  name: r.name,
  geometry: {
    coordinates: Array.from(r.geom.coordinates),
    type: r.geom.type
  },
  radius: r.radius ? r.radius : null,
  type: r.type,
  color: r.color
})

const filterRefsPoints = r => {
  if (r.type === 1) return r
}

const mapRefsPoints = r => ({
  id: r.id,
  name: r.name,
  geometry: {
    coordinates: Array.from(r.geom.coordinates),
    type: 'Circle'
  },
  type: r.type,
  radius: r.radius,
  color: r.color
})

const fRefs = refs => toObjBy('id')(Object.values(refs).map(mapRef))
const fRefsRadius = refs =>
  toObjBy('id')(
    Object.values(refs)
      .filter(filterRefsPoints)
      .map(mapRefsPoints)
  )

const referencesMap = ($el, references, objects, store) => {
  objects = fObjs(objects)
  const markers = mapO(createMarker())(objects)
  const labelMarkers = mapO(createLabelMarker())(objects)

  let refPointsRadius = fRefsRadius(references)
  references = fRefs(references)

  let selObjHandler = null
  const onClick = ev => {
    ev.domEvent && ev.domEvent.stopPropagation()
    if (!selObjHandler) return
    const { target } = ev
    selObjHandler(target.getId())
  }

  const referencesCluster = new VectorLayer('Refs', [])
  const refsPointsRadiusLayer = new VectorLayer('Radio Puntos', [])
  const vectorLayer = new VectorLayer('Drawing VectorLayer', [])

  const objectsCluster = new ClusterLayer('Unidades', Object.values(markers), {
    maxClusterZoom: 13,
    visible: false,
    textSymbol: {
      textFaceName: '"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"',
      textSize: 18,
      textDy: 2,
      textWeight: 'bold',
      geometryEvents: false
    }
  })
  const labelsCluster = new ClusterLayer('Labels', Object.values(labelMarkers), {
    symbol: {
      markerType: 'ellipse',
      markerFillOpacity: 0,
      markerLineOpacity: 0
    },
    visible: false,
    maxClusterZoom: 13,
    geometryEvents: false,
    drawClusterText: false
  })

  const refsMarkers = mapO(createRefMarker())(references)
  const refsCircleMarkers = mapO(createRefCircle())(refPointsRadius)

  const { map, destroy } = BaseMap($el, [refsPointsRadiusLayer, referencesCluster, vectorLayer, objectsCluster, labelsCluster], ['Unidades', 'Labels', 'Refs', 'Radio Puntos', 'Drawing VectorLayer'])

  map.on('click', () => drawing && !drawing.isEditing() && selObjHandler && selObjHandler())

  let drawing = null
  let drawColor = '#209CEE'
  let drawingName = ''

  const drawTool = new DrawTool({
    mode: 'Point'
  })
    .addTo(map)
    .disable()

  drawTool.on('drawend', param => {
    drawTool.disable()
    param.geometry.setId('drawing')
    param.geometry.setSymbol([
      {
        polygonFill: drawColor,
        polygonOpacity: 0.4,
        lineColor: drawColor,
        lineWidth: 2,
        lineOpacity: 1
      },
      {
        textName: drawingName,
        ...invTextSymbol
      }
    ])
    drawing = param.geometry
    vectorLayer.addGeometry(drawing)
  })

  const setDrawMode = mode => {
    drawing = null
    drawingName = null
    drawColor = '#209CEE'
    drawTool.setMode(mode).enable()
  }

  const setDrawColor = color => {
    drawColor = color
    if (drawing) {
      drawing.updateSymbol([
        {
          polygonFill: color,
          lineColor: color
        },
        {}
      ])
    }
  }
  const setDrawingName = name => {
    drawing.updateSymbol([
      {},
      {
        textName: name
      }
    ])
  }

  const getDrawing = () => { return drawing }

  const setDrawing = reference => {
    vectorLayer.clear()
    let marker = null
    if (reference.type === 1) {
      marker = createRefCircle()(mapRefsPoints(reference))
      referencesCluster.getGeometryById(reference.id).hide()
    } else {
      marker = createRefMarker()(mapRef(reference))
      referencesCluster.getGeometryById(reference.id).hide()
    }
    if (marker) {
      marker.setId('drawing')
      marker.setSymbol([
        {
          polygonFill: '#' + reference.color,
          polygonOpacity: 0.4,
          lineColor: '#' + reference.color,
          lineWidth: 2,
          lineOpacity: 1
        },
        {
          textName: reference.name,
          ...invTextSymbol
        }
      ])
      const extent = marker.getExtent()
      map.fitExtent(extent, -2)
    }

    drawing = marker
    vectorLayer.addGeometry(drawing)
  }
  const unsetDrawing = reference => {
    if (reference.type === 1) { refsPointsRadiusLayer.getGeometryById(reference.id).show() }
    referencesCluster.getGeometryById(reference.id).show()
    vectorLayer.clear()
  }

  const startEditDrawing = () => drawing.startEdit()

  const cancelEditDrawing = () => drawing.endEdit()

  const clearDrawing = () => {
    drawTool.disable()
    vectorLayer.clear()
  }

  referencesCluster.addGeometry(Object.values(refsMarkers))
  refsPointsRadiusLayer.addGeometry(Object.values(refsCircleMarkers))

  map.fitExtent(referencesCluster.getExtent())

  const updateReferences = refs => {
    const newRefs = fRefs(refs)
    const newCircles = fRefsRadius(refs)
    for (const k in references) {
      !newRefs[k] && refsMarkers[k] && refsMarkers[k].remove()
    }
    for (const k in newRefs) {
      if (references[k]) updateRefMarker(refsMarkers[k], newRefs[k])
      else {
        const m = createRefMarker(onClick)(newRefs[k])
        refsMarkers[k] = m
        referencesCluster.addGeometry(m)
      }
    }
    references = newRefs

    for (const k in refPointsRadius) {
      !newCircles[k] && refsCircleMarkers[k] && refsCircleMarkers[k].remove()
    }
    for (const k in newCircles) {
      if (refPointsRadius[k]) {
        updateCircleMarker(refsCircleMarkers[k], newCircles[k])
      } else {
        const m = createRefCircle()(newCircles[k])
        refsCircleMarkers[k] = m
        refsPointsRadiusLayer.addGeometry(m)
      }
    }
    refPointsRadius = newCircles
  }

  const onSelect = cb => {
    selObjHandler = cb
  }

  return {
    map,
    onSelect,
    destroy,
    updateReferences,
    setDrawMode,
    clearDrawing,
    drawColor,
    setDrawColor,
    setDrawingName,
    setDrawing,
    startEditDrawing,
    cancelEditDrawing,
    unsetDrawing,
    getDrawing
  }
}

export default referencesMap
