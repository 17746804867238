<template>
  <i-select :value="value" filterable :clearable="true" @input="onInput"
    @on-query-change="onQuery" @on-clear="onClear" @on-change="onChange">
    <i-option v-for="dr in options" :key="dr.driverId" :value="dr.driverId">{{dr.name}}</i-option>
  </i-select>
</template>

<script>
const filter = (q, xs) => xs.filter(x => x && x.name && x.name.toLowerCase().includes(q))

export default {
  data: () => ({ filtered: null }),
  computed: {
    driversList () { return this.$store.state.data.driversList || [] },
    drivers () { return this.$store.state.data.drivers },
    options () { return this.filtered || this.driversList.slice(0, 200) }
  },
  props: {
    value: null,
    filterBy: {
      type: String,
      required: false
    }
  },
  methods: {
    onQuery (query) {
      if (query !== '') {
        const q = query.toLowerCase().trim()
        this.filtered = q ? filter(q, this.driversList).slice(0, 200) : null
      }
    },
    onInput (v) { this.$emit('input', v) },
    onClear () {
      this.filtered = this.driversList
      this.$emit('on-clear')
    },
    onChange (v) {
      if (!v) {
        this.filtered = this.driversList
      }
    }
  },
  watch: {
    filterBy (value) {
      if (value) {
        this.filtered = this.driversList
      } else this.filtered = null
    }
  }
}
</script>
