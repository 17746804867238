import Vue from 'vue'
// import { VuePlausible } from 'vue-plausible'
import App from './App.vue'
import router from './router'
import store from './store'

import './styles/index.less'
import iview from './iview'

Vue.use(iview)

// Vue.use(VuePlausible, {
//   apiHost: 'https://medidores.transdat.com.ar'
// })

// Vue.$plausible.enableAutoPageviews()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
