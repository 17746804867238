import { TileLayer } from 'maptalks'

const common = {
  visible: false,
  repeatWorld: false,
  fadeAnimation: false,
  tileRetryCount: 3,
  placeholder: true
}

export default function createTileLayers () {
  const cartoLight = new TileLayer('Carto light', {
    urlTemplate: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
    subdomains: ['a', 'b', 'c', 'd'],
    ...common
  })

  const cartoDark = new TileLayer('Carto dark', {
    urlTemplate: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
    subdomains: ['a', 'b', 'c', 'd'],
    ...common
  })

  const googleSat = new TileLayer('GoogleMaps Sat', {
    urlTemplate: 'https://mt{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    subdomains: ['0', '1', '2', '3'],
    ...common
  })

  const openStreet = new TileLayer('OpenStreetMaps', {
    urlTemplate: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    subdomains: ['a', 'b', 'c'],
    ...common
  })

  const googleMap = new TileLayer('GoogleMaps', {
    urlTemplate: 'https://mt{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    subdomains: ['0', '1', '2', '3'],
    ...common
  })

  const googleHib = new TileLayer('Google Hibrido', {
    urlTemplate: 'https://mt{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
    subdomains: ['0', '1', '2', '3'],
    ...common,
    visible: true
  })

  return [
    openStreet,
    cartoLight,
    cartoDark,
    googleMap,
    googleSat,
    googleHib
  ]
}
