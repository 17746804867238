import { mapToTree } from '@/util/companies'

const state = () => ({ viewed: null, mapExtent: null, baseLayer: null, noClusters: true })

const SET_VIEWED = (state, sel) => { state.viewed = sel }

const DEL_VIEWED = (state, sel) => { state.viewed = null }

const RESET = old => Object.assign(old, state())

const SET_MAP_EXTENT = (state, extent) => { state.mapExtent = extent }

const SET_BASELAYER = (state, id) => { state.baseLayer = id }

const SET_NO_CLUSTERS = (state, id) => { state.noClusters = id }

const getViewed = (state, getters, rootState) =>
  state.viewed && rootState.data.objects[state.viewed]

const getTree = (state, getters, rootState) => mapToTree(rootState.data.companies)

const setViewed = (ctx, sel) => { ctx.commit('SET_VIEWED', sel) }

const setBaseLayer = (ctx, id) => { ctx.commit('SET_BASELAYER', id) }

const setMapExtent = (ctx, extent) => { ctx.commit('SET_MAP_EXTENT', extent) }

const setNoClusters = (ctx, cluster) => { ctx.commit('SET_NO_CLUSTERS', cluster) }

export default {
  namespaced: true,
  state,
  actions: { setViewed, setMapExtent, setBaseLayer, setNoClusters },
  getters: { getViewed, getTree },
  mutations: { SET_VIEWED, DEL_VIEWED, RESET, SET_MAP_EXTENT, SET_BASELAYER, SET_NO_CLUSTERS }
}
