<template>
  <iLayout class="tdLayout">
    <app-header>
      <slot>
        <div class="seguimientosHeaderBtnGroup" size="large">
          <iTooltip class="seguimientosHeaderTooltip" content="Vista de 2 mapas" placement="bottom">
            <iButton class="seguimientosHeaderBtn" @click="setMapQ(2)" type="text">2</iButton>
          </iTooltip>
          <iTooltip class="seguimientosHeaderTooltip" content="Vista de 4 mapas" placement="bottom">
            <iButton class="seguimientosHeaderBtn" @click="setMapQ(4)" type="text">4</iButton>
          </iTooltip>
          <iTooltip class="seguimientosHeaderTooltip" content="Vista de 6 mapas" placement="bottom">
            <iButton class="seguimientosHeaderBtn" @click="setMapQ(6)" type="text">6</iButton>
          </iTooltip>
        </div>
      </slot>
    </app-header>
    <iContent class="layoutContent">
      <iLayout class="seguimientosLayout">
        <iContent class="seguimientosLayoutContent">
          <iRow class="seguimientosRow">
            <iCol class="seguimientosCol">
              <SeguimientosMapContainer :mapId="0" :mapZoom="12"/>
            </iCol>
            <iCol class="seguimientosCol">
              <SeguimientosMapContainer :mapId="1" :mapZoom="12"/>
            </iCol>
            <iCol class="seguimientosCol" v-if="mapQ === 6">
              <SeguimientosMapContainer :mapId="2" :mapZoom="12"/>
            </iCol>
          </iRow>
          <iRow class="seguimientosRow" v-if="mapQ >= 4">
            <iCol class="seguimientosCol">
              <SeguimientosMapContainer :mapId="3" :mapZoom="12"/>
            </iCol>
            <iCol class="seguimientosCol" v-if="mapQ >= 4">
              <SeguimientosMapContainer :mapId="4" :mapZoom="12"/>
            </iCol>
            <iCol class="seguimientosCol" v-if="mapQ === 6">
              <SeguimientosMapContainer :mapId="5" :mapZoom="12"/>
            </iCol>
          </iRow>
        </iContent>
      </iLayout>
    </iContent>
  </iLayout>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import SeguimientosMapContainer from '@/components/SeguimientosMapContainer'

export default {
  computed: {
    mapQ: {
      get () {
        return this.$store.state.seguimientosView.mapQuant
      },
      set (value) {
        this.$store.dispatch('seguimientosView/setMapQuant', value)
      }
    }
  },
  components: { AppHeader, SeguimientosMapContainer },
  methods: {
    setMapQ (n) { this.mapQ = n }
  },
  created () { this.$store.dispatch('startUpdate') },
  beforeDestroy () { this.$store.dispatch('stopUpdate') }
}
</script>
