import axios from 'axios'
import { addRetry } from '@/util/axiosHelpers'

const AUTH_URL = '/auth'
const OBJECTS_URL = '/v1/objects/'
const OBJECTS_DATA_URL = '/v2/objects/'
const GEOFENCES_URL = '/v1/geofences/'
const USER_URL = '/v1/users/'
const DRIVERS_URL = '/v1/drivers/'
const DRIVER_REPORT_URL = '/v1/reports/drivers'
// const PDF_URL = 'http://localhost:4000/api/tracks_reports/'

export const api = axios.create()

addRetry(api)

export const getMe = () => api.get(USER_URL + 'me')

export const getAuth = creds => api.post(AUTH_URL, creds)

export const getObjects = () => api.get(OBJECTS_URL)

export const getObjectsData = () => api.get(OBJECTS_DATA_URL)

export const getObject = id => api.get(OBJECTS_URL + id)

export const getGeofences = () => api.get(GEOFENCES_URL)

export const createGeofence = (g) => api.post(GEOFENCES_URL, g)

export const updateGeofence = (g, id) => api.put(GEOFENCES_URL + id, g)

export const deleteGeofence = (id) => api.delete(GEOFENCES_URL + id)

export const getDrivers = () => api.get(DRIVERS_URL)

export const setSpeedLimit = ({ object, limit }) =>
  api.put(`${OBJECTS_URL}${object}/speedLimit/${limit}`)

export const getObjectReport = ({ object, ...params }) =>
  api.get(OBJECTS_URL + object + '/report', { params })

export const getObjectReportCsv = ({ object, ...params }) =>
  api.get(OBJECTS_URL + object + '/csv', { params })

export const getAllReport = ({ ...params }) =>
  api.get('/v1/reports', { params })

export const changePass = (params) => api.put(USER_URL + 'pass/', params)

export const getTracking = ({ object, ...params }) =>
  api.get(OBJECTS_URL + object + '/tracking', { params })

export const getDriverReportByObj = ({ object, from, to }) =>
  api.get(DRIVER_REPORT_URL, { params: { objectId: object, from, to } })

export const getDriverReportByDriver = ({ id, from, to }) =>
  api.get(DRIVER_REPORT_URL, { params: { from, to, driverId: id } })

export const nominatiRevGeocode = (lat, lng) => api.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`)

// export const getObjectReportPdf = ({ object, ...params }) =>
//   api.post(PDF_URL, { params })
