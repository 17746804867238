import lang from 'view-design/dist/locale/es-ES'

import {
  locale,
  Alert,
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  Collapse,
  Content,
  DatePicker,
  Divider,
  Footer,
  Form,
  Header,
  Icon,
  Input,
  InputNumber,
  Layout,
  Menu,
  Message,
  Modal,
  Notice,
  Option,
  Panel,
  Radio,
  Row,
  Select,
  Sider,
  Spin,
  Table,
  Tabs,
  Timeline,
  Tooltip
} from 'view-design'

const iview = {
  iButton: Button,
  iButtonGroup: Button.Group,
  iSelect: Select,
  iRow: Row,
  iCol: Col,
  iMenu: Menu,
  iMenuItem: Menu.Item,
  iMenuGroup: Menu.Group,
  iRadio: Radio,
  iRadioGroup: Radio.Group,
  iPanel: Panel,
  iForm: Form,
  iFormItem: Form.Item,
  iIcon: Icon,
  iInput: Input,
  iDatePicker: DatePicker,
  iLayout: Layout,
  iSider: Sider,
  iHeader: Header,
  iContent: Content,
  iFooter: Footer,
  iCard: Card,
  iModal: Modal,
  iOption: Option,
  iTooltip: Tooltip,
  iInputNumber: InputNumber,
  iDivider: Divider,
  iTabs: Tabs,
  iTabPane: Tabs.Pane,
  iTimeline: Timeline,
  iTimelineItem: Timeline.Item,
  iAlert: Alert,
  iAvatar: Avatar,
  iCollapse: Collapse,
  iTable: Table,
  iCarousel: Carousel,
  iCarouselItem: Carousel.Item
}
export default function (Vue) {
  locale(lang)

  Object.keys(iview).forEach(key => {
    Vue.component(key, iview[key])
  })

  // Vue.prototype.$Loading = LoadingBar
  Vue.prototype.$Message = Message
  Vue.prototype.$Modal = Modal
  Vue.prototype.$Notice = Notice
  Vue.prototype.$Spin = Spin
}
