<template>
  <iModal v-model="showModal" width="500" title="Cambio de contraseña">
    <iForm ref="formCustom" :model="formCustom" :rules="ruleCustom" :label-width="80">
      <iFormItem label="Contraseña Actual" prop="pass">
        <iInput type="password" v-model="formCustom.pass"/>
      </iFormItem>
      <iFormItem label="Contraseña Nueva" prop="newPass">
        <iInput type="password" v-model="formCustom.newPass"/>
      </iFormItem>
      <iFormItem label="Repetir Contraseña Nueva" prop="newPassCheck">
        <iInput type="password" v-model="formCustom.newPassCheck"/>
      </iFormItem>
    </iForm>
    <div slot="footer">
      <iButton type="primary" @click="handleSubmit" :loading="loading">Aceptar</iButton>
    </div>
  </iModal>
</template>

<script>
import { changePass } from '@/api'

export default {
  data () {
    return {
      loading: false,
      formCustom: {
        pass: '',
        newPassCheck: '',
        newPass: ''
      },
      ruleCustom: {
        pass: [
          { validator: this.validatePass, trigger: 'blur' }
        ],
        newPass: [
          { validator: this.validateNewPass, trigger: 'blur' }
        ],
        newPassCheck: [
          { validator: this.validatePassCheck, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    showModal: {
      get () { return this.show },
      set (v) { this.$emit('update:show', v) }
    }
  },
  props: {
    show: Boolean
  },
  methods: {
    validatePass (rule, value, callback) {
      if (value === '') {
        callback(new Error('Por favor ingrese su contraseña actual.'))
      } else {
        if (this.formCustom.newPassCheck !== '') {
          this.$refs.formCustom.validateField('newPassCheck')
        }
        callback()
      }
    },
    validateNewPass (rule, value, callback) {
      if (value === '') {
        callback(new Error('Por favor ingrese su nueva contraseña.'))
      } else {
        if (this.formCustom.newPassCheck !== '') {
          this.$refs.formCustom.validateField('newPassCheck')
        }
        callback()
      }
    },
    validatePassCheck (rule, value, callback) {
      if (value === '') {
        callback(new Error('Por favor ingrese su nueva contraseña.'))
      } else if (value !== this.formCustom.newPass) {
        callback(new Error('Su nueva contraseña no coincide.'))
      } else {
        callback()
      }
    },
    handleSubmit () {
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          this.loading = true
          const params = { old: this.formCustom.pass, pass: this.formCustom.newPass }
          changePass(params).then(() => {
            this.$Message.success('Success!')
          },
          (error) => {
            this.$Message.error(error.response.data.uiMsg)
          })
          this.loading = false
        } else {
          this.$Message.error('Compruebe los datos ingresados')
        }
      })
    }
  }
}
</script>

<style>

</style>
