import { Confirm } from '@/util/confirm'
import { wFormat } from '@/util/dates'

const ConfirmTracking = (vm, { from, to, object, driver }) => Confirm(vm, {
  title: 'Confirmar',
  content: `<div>¿Desea dar permiso temporal para ver?:</div>
    <h4>${object}</h4>
    <div>Desde: <b>${wFormat(from)}</b></div>
    <div>Hasta: <b>${wFormat(to)}</b></div>
    <div>Chofer: <b>${driver}</b></div>
    <div class="ivu-alert ivu-alert-warning">
      Este permiso es irrevocable durante el tiempo asignado
    </div>`
})

export default ConfirmTracking
