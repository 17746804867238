<template>
  <iCard class="seguimientosMapContainer">
    <object-select :value="object" @input="select" @on-clear="onClear" />
    <div ref="map" class="seguimientosMap"/>
    <div class="seguimientosTopInfoPanel" v-if="viewed">
      <span>{{viewed.gps.dir}}</span>
    </div>
    <div class="seguimientosBottomInfoPanel" v-if="viewed">
      <span>Velocidad: {{viewed.gps.speed}}km/h</span>
      <iDivider type="vertical" />
      <span>Fecha: {{gpsDate}}</span>
    </div>
  </iCard>
</template>

<script>
import { shortFmt } from '@/util/dates'
import SeguimientosMap from '@/maps/seguimientosMap'
import ObjectSelect from './ObjectSelect'

const isNum = n => typeof n === 'number'

export default {
  components: { ObjectSelect },
  props: {
    mapId: Number,
    mapZoom: Number
  },
  computed: {
    gpsDate () {
      const date = shortFmt(this.viewed.gps.date)
      return date
    },
    viewed () { return this.$store.getters['seguimientosView/getViewed'](this.mapId) },
    object () { return this.$store.state.seguimientosView.vieweds[this.mapId] }
  },
  methods: {
    select (id) {
      isNum(id) && this.$store.dispatch('seguimientosView/setViewed', {
        map: this.mapId,
        object: id
      })
    },
    onClear () {
      this._map.clearMap()
      this.$store.dispatch('seguimientosView/setViewed', {
        map: this.mapId,
        object: null
      })
    }
  },
  watch: {
    viewed (value) {
      if (value) {
        this._map.update(value)
        this._map.animateTo(value)
      }
    }
  },
  mounted () {
    this._map = SeguimientosMap(this.$refs.map)
    this._map.update(this.viewed)
    if (this.viewed) { this._map.animateTo(this.viewed) }
  },
  beforeDestroy () { this._map && this._map.destroy() }
}
</script>
