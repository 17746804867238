import { Map, control, DomUtil } from 'maptalks'

/**
 * @property {Object} options - options
 * @property {Object} [options.position='top-right'] - position of the control
 * @property {Object} [options.baseTitle='Base Layers'] - title of the base layers
 * @property {Object} [options.overlayTitle='Layers'] - title of the overlay layers
 * @property {Object} [options.excludeLayers=[]] - ids of layers that don't display in layerswitcher
 * @property {Object} [options.containerClass=maptalks-layer-switcher] - layerswitcher's container div's CSS class
 *
 * @memberOf control.LayerSwitcher
 * @instance
 */
const options = {
  position: 'top-right',
  baseTitle: 'Base Layers',
  overlayTitle: 'Layers',
  excludeLayers: [],
  containerClass: 'maptalks-layer-switcher'
}

/**
 * @classdesc
 * A layerswither control for the map.
 * @category control
 * @extends control.Control
 * @memberOf control
 * @example
 * var layerswither = new Layerswither({
 *     position : {'top': '0', 'right': '0'}
 * }).addTo(map);
 */
class LayerSwitcher extends control.Control {
  /**
   * method to build DOM of the control
   * @return {HTMLDOMElement}
   */
  buildOn () {
    const container = (this.container = DomUtil.createEl(
      'div',
      this.options.containerClass
    ))
    const panel = (this.panel = DomUtil.createEl('div', 'panel'))
    const button = (this.button = DomUtil.createEl('button'))
    const icon = (this.icon = DomUtil.createEl('i', 'ivu-icon ivu-icon-md-layers'))
    button.appendChild(icon)
    container.appendChild(button)
    container.appendChild(panel)

    const map = this.getMap()
    const layers = map.getLayers()
    if (layers.length) {
      const div = DomUtil.createEl('div', 'homeMapButtonGroup')
      if (map.getLayer('Unidades')) {
        const objectsButton = (this._objectsButton = DomUtil.createEl('button', 'homeMapButton'))
        const objectsIcon = (this.icon = DomUtil.createEl('i', 'ivu-icon ivu-icon-md-truck'))
        objectsButton.title = 'Mostrar/Ocultar unidades'
        objectsButton.appendChild(objectsIcon)
        div.appendChild(objectsButton)

        const toggleClustersButton = (this._toggleClustersButton = DomUtil.createEl('button', 'homeMapButton'))
        const toggleClustersIcon = (this.icon = DomUtil.createEl('i', 'ivu-icon ivu-icon-md-square-outline'))
        toggleClustersButton.appendChild(toggleClustersIcon)
        toggleClustersButton.title = 'Agrupar/Desagrupar unidades'
        div.appendChild(toggleClustersButton)
      }
      if (map.getLayer('Referencias')) {
        const referencesButton = (this._referencesButton = DomUtil.createEl('button', 'homeMapButton'))
        const referencesIcon = (this.icon = DomUtil.createEl('i', 'ivu-icon ivu-icon-md-pin'))
        referencesButton.appendChild(referencesIcon)
        referencesButton.title = 'Mostrar/Ocultar referencias'
        div.appendChild(referencesButton)
      }
      container.appendChild(div)
    }

    this._registerDomEvents()

    return container
  }

  onAdd () {
    DomUtil.on(this.button, 'mouseover', this._show, this)
    DomUtil.on(this.panel, 'mouseleave', this._hide, this)
    DomUtil.on(this.getMap(), 'click', this._hide, this)
  }

  onRemove () {
    if (this.panel) {
      DomUtil.off(this.button, 'mouseover', this._show, this)
      DomUtil.off(this.panel, 'mouseleave', this._hide, this)
      DomUtil.off(this.getMap(), 'click', this._hide, this)
      DomUtil.off(this._objectsButton, 'click', this._showHideObjects, this)
      DomUtil.off(this._referencesButton, 'click', this._showHideReferences, this)
      DomUtil.off(this._toggleClustersButton, 'click', this._toggleClusters, this)
      DomUtil.removeDomNode(this.panel)
      DomUtil.removeDomNode(this.button)
      DomUtil.removeDomNode(this.icon)
      DomUtil.removeDomNode(this._objectsButton)
      DomUtil.removeDomNode(this._referencesButton)
      DomUtil.removeDomNode(this._toggleClustersButton)
      delete this.panel
      delete this.button
      delete this.icon
      delete this.container
      delete this._objectsButton
      delete this._referencesButton
      delete this._toggleClustersButton
    }
  }

  _registerDomEvents () {
    DomUtil.on(this._objectsButton, 'click', this._showHideObjects, this)
    DomUtil.on(this._toggleClustersButton, 'click', this._toggleClusters, this)
    DomUtil.on(this._referencesButton, 'click', this._showHideReferences, this)
  }

  _showHideObjects () {
    const layer = this.getMap().getLayer('Unidades')
    const labelLayer = this.getMap().getLayer('Labels')
    if (!layer.isVisible()) {
      layer.show()
      if (!labelLayer.isVisible()) labelLayer.show()
    } else {
      layer.hide()
      labelLayer.hide()
    }
  }

  _toggleClusters () {
    const layer = this.getMap().getLayer('Unidades')
    const labelLayer = this.getMap().getLayer('Labels')
    const clusterZoom = layer.options.maxClusterZoom
    layer.config('maxClusterZoom', clusterZoom === 5 ? 12 : 5)
    labelLayer.config('maxClusterZoom', clusterZoom === 5 ? 12 : 5)
  }

  _showHideReferences () {
    const markersLayer = this.getMap().getLayer('Referencias')
    const circlesLayer = this.getMap().getLayer('Radio Puntos')
    if (!markersLayer.isVisible()) {
      markersLayer.show()
      circlesLayer.show()
    } else {
      markersLayer.hide()
      circlesLayer.hide()
    }
  }

  _show () {
    if (!DomUtil.hasClass(this.container, 'shown')) {
      DomUtil.addClass(this.container, 'shown')
      this._createPanel()
    }
  }

  _hide (e) {
    if (!this.panel.contains(e.toElement || e.relatedTarget)) {
      DomUtil.setClass(this.container, this.options.containerClass)
    }
  }

  _createPanel () {
    this.panel.innerHTML = ''
    const ul = DomUtil.createEl('ul')
    this.panel.appendChild(ul)
    this._renderLayers(this.getMap(), ul)
  }

  _renderLayers (map, elm) {
    const base = map.getBaseLayer()
    const layers = map.getLayers(layer => {
      return this._isExcluded(layer)
    })
    const len = layers.length
    if (base) {
      const baseLayers = base.layers || [base]
      const li = DomUtil.createEl('li', 'group')
      const ul = DomUtil.createEl('ul')
      const label = DomUtil.createEl('label')
      label.innerHTML = this.options.baseTitle
      li.appendChild(label)
      for (let i = 0, len = baseLayers.length; i < len; i++) {
        const layer = baseLayers[i]
        if (this._isExcluded(layer)) {
          ul.appendChild(this._renderLayer(baseLayers[i], true))
          li.appendChild(ul)
          elm.appendChild(li)
        }
      }
    }

    if (len) {
      const li = DomUtil.createEl('li', 'group')
      const ul = DomUtil.createEl('ul')
      const label = DomUtil.createEl('label')
      label.innerHTML = this.options.overlayTitle
      li.appendChild(label)
      for (let i = 0; i < len; i++) {
        const layer = layers[i]
        if (this._isExcluded(layer)) {
          ul.appendChild(this._renderLayer(layer))
        }
      }
      li.appendChild(ul)
      elm.appendChild(li)
    }
  }

  _isExcluded (layer) {
    const id = layer.getId()
    const excludeLayers = this.options.excludeLayers
    return !(excludeLayers.length && excludeLayers.indexOf(id) >= 0)
  }

  _renderLayer (layer, isBase) {
    const li = DomUtil.createEl('li', 'layer')
    const label = DomUtil.createEl('label')
    const input = DomUtil.createEl('input')
    const map = this.getMap()
    const visible = layer.options.visible
    layer.options.visible = true
    const enabled = layer.isVisible()
    layer.options.visible = visible
    li.className = 'layer'
    if (isBase) {
      input.type = 'radio'
      input.name = 'base'
    } else {
      input.type = 'checkbox'
    }

    input.checked = visible && enabled
    if (!enabled) {
      input.setAttribute('disabled', 'disabled')
    }

    input.onchange = e => {
      if (e.target.type === 'radio') {
        const baseLayer = map.getBaseLayer()
        const baseLayers = baseLayer.layers
        if (baseLayers) {
          for (let i = 0, len = baseLayers.length; i < len; i++) {
            const _baseLayer = baseLayers[i]
            _baseLayer[_baseLayer === layer ? 'show' : 'hide']()
          }
        } else if (!baseLayer.isVisible()) {
          baseLayer.show()
        }
        map._fireEvent('setbaselayer')
      } else {
        layer[e.target.checked ? 'show' : 'hide']()
      }
      this.fire('layerchange', { target: layer })
    }
    li.appendChild(input)
    label.innerHTML = layer.getId()
    li.appendChild(label)
    return li
  }
}

LayerSwitcher.mergeOptions(options)

Map.mergeOptions({
  layerSwitcherControl: false
})

Map.addOnLoadHook(function () {
  if (this.options.layerSwitcherControl) {
    this.layerSwitcherControl = new LayerSwitcher(
      this.options.layerSwitcherControl
    )
    this.addControl(this.layerSwitcherControl)
  }
})

export default LayerSwitcher
