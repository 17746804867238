const ls = window && window.localStorage
const watchOps = { deep: true, inmediate: true }
var dlv = key => obj => key.split('/').reduce((o, k) => o && o[k], obj)

/** @param {String} moduleName */
export default function (moduleName) {
  const getter = dlv(moduleName)
  const save = data => ls.setItem(moduleName, JSON.stringify(data))
  /** @param {Store} store */
  return store => {
    try {
      const data = ls.getItem(moduleName)
      if (data) store.commit(moduleName + '/INIT', JSON.parse(data))
    } catch (e) { ls.removeItem(moduleName) }
    store.watch(getter, save, watchOps)
  }
}
