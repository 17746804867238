import { Map, control, DomUtil } from 'maptalks'

/**
 * @property {Object}   options - options
 * @property {String|Object}   [options.position="top-left"]  - position of the zoom control.
 * @property {Boolean}  [options.slider=false]                         - Whether to display the slider
 * @property {Boolean}  [options.zoomLevel=false]                      - Whether to display the text box of zoom level
 * @memberOf control.Zoom
 * @instance
 */
const options = {
  position: 'top-left',
  slider: false,
  zoomLevel: false,
  seamless: false
}

/**
 * @classdesc
 * A zoom control with buttons to zoomin/zoomout and a slider indicator for the zoom level.
 * @category control
 * @extends control.Control
 * @memberOf control
 * @example
 * var zoomControl = new Zoom({
 *     position : 'top-left',
 *     slider : true,
 *     zoomLevel : false
 * }).addTo(map);
 */
class Zoom extends control.Control {
  /**
     * method to build DOM of the control
     * @param  {Map} map map to build on
     * @return {HTMLDOMElement}
     */
  buildOn (map) {
    const dom = DomUtil.createEl('div', 'maptalks-zoom')

    const zoomDOM = DomUtil.createEl('div', 'maptalks-zoom-slider')

    const zoomInButton = DomUtil.createEl('a', 'maptalks-zoom-zoomin')
    const zoomInIcon = DomUtil.createEl('i', 'ivu-icon ivu-icon-md-add')

    zoomInButton.appendChild(zoomInIcon)
    zoomDOM.appendChild(zoomInButton)
    this._zoomInButton = zoomInButton

    const zoomOutButton = DomUtil.createEl('a', 'maptalks-zoom-zoomout')
    const zoomOutIcon = DomUtil.createEl('i', 'ivu-icon ivu-icon-md-remove')
    zoomOutButton.appendChild(zoomOutIcon)
    zoomDOM.appendChild(zoomOutButton)
    this._zoomOutButton = zoomOutButton

    dom.appendChild(zoomDOM)
    this._registerDomEvents()

    return dom
  }

  onRemove () {
    if (this._zoomInButton) DomUtil.off(this._zoomInButton, 'click', this._onZoomInClick, this)
    if (this._zoomOutButton) DomUtil.off(this._zoomOutButton, 'click', this._onZoomOutClick, this)
  }

  _registerDomEvents () {
    if (this._zoomInButton) DomUtil.on(this._zoomInButton, 'click', this._onZoomInClick, this)
    if (this._zoomOutButton) DomUtil.on(this._zoomOutButton, 'click', this._onZoomOutClick, this)
  }

  _onZoomInClick (e) {
    DomUtil.preventDefault(e)
    this.getMap().zoomIn()
  }

  _onZoomOutClick (e) {
    DomUtil.preventDefault(e)
    this.getMap().zoomOut()
  }
}

Zoom.mergeOptions(options)

Map.mergeOptions({
  zoomControl: false
})

Map.addOnLoadHook(function () {
  if (this.options.zoomControl) {
    this.zoomControl = new Zoom(this.options.zoomControl)
    this.addControl(this.zoomControl)
  }
})

export default Zoom
