export const map = fn => xs => xs.map(fn)
export const pick = keys => {
  if (typeof keys === 'string') keys = keys.split(/ +/)
  return (obj = {}) => {
    const ret = {}
    for (const key of keys) if (obj[key] != null) ret[key] = obj[key]
    return ret
  }
}
export const only = (keys = [], obj = {}) => {
  const ret = {}
  for (const key of keys) if (obj[key] != null) ret[key] = obj[key]
  return ret
}

const aKeysToMap = (r, k) => (((r[k] = 1), r))

export const omit = keys => {
  if (typeof keys === 'string') keys = keys.split(/ +/)
  keys = keys.reduce(aKeysToMap, {})
  return (obj = {}) => {
    const ret = {}
    for (const k in obj) if (!keys[k]) ret[k] = obj[k]
    return ret
  }
}

export const hasOtherProp = (keys = [], obj = {}) => Object.keys(omit(keys)(obj)).length

export const propOr = (val, p) => obj =>
  obj != null && obj[p] != null ? obj[p] : val

// Array fp utils
export const concat = a => b => a.concat(b)
export const sort = fn => xs => xs.slice().sort(fn)
export const reduce = fn => acc => xs => xs.reduce(fn, acc)
export const cloneA = (...arg) => [].concat(arg)
export const dlv = key => obj => key.split('/').reduce((o, k) => o && o[k], obj)

export const reduceO = fn => xs => xs.reduce(fn, {})

export const groupBy = prop => reduceO((o, v) => {
  (o[v[prop]] || (o[v[prop]] = [])).push(v)
  return o
})
export const getK = obj => k => obj[k]
export const vals = obj => Object.keys(obj).map(getK(obj))
export const pipe = (...fns) => x => fns.reduce((r, f) => f(r), x)

export const strComp = p => {
  p = propOr('', p)
  return (a, b) => p(a).localeCompare(p(b))
}

// Vue helpers
const splitClasses = str => {
  const cls = str.split('.')
  return [cls.shift() || 'div', cls.join(' ')]
}

export const fComp = tag => {
  const [el, cls] = splitClasses(tag)
  return {
    functional: true,
    render: (h, { data, children }) => {
      data.staticClass = `${cls} ${data.staticClass || ''}`.trim()
      return h(el, data, children)
    }
  }
}

/**
 * map object properties
 * @function mapO
 * @param {Function} f
 * @returns {function(Object): Object}
 */
export const mapO = f => o => {
  const r = {}
  for (const k in o) r[k] = f(o[k])
  return r
}

export const filterO = f => o => {
  const r = {}
  for (const k in o) if (f(o[k])) r[k] = f(o[k])
  return r
}

export const toObjBy = key => xs => {
  const r = {}
  for (const x of xs) if (x[key] != null) r[x[key]] = x
  return r
}

export const toArr = (o = {}) => {
  const a = []
  for (const k in o) a.push(o[k])
  return a
}
