<template>
  <i-select :value="value" filterable :clearable="true" @on-query-change="onQuery" @on-clear="onClear"
    @on-change="onChange">
    <i-option v-for="ob in options" :key="ob.object" :value="ob.object">{{ob.name}}</i-option>
  </i-select>
</template>

<script>
const filter = (q, xs) => xs.filter(x => x && x.name && x.name.toLowerCase().includes(q))

const filterByType = (objList, filterBy) =>
  filterBy === 'forklift'
    ? objList.filter(o => (o.info.type === 38))
      .map(o => ({ name: o.name, object: o.object }))
    : filterBy === 'roadMachine' ? objList.filter(({ info: { type } }) => (type === 10 || type === 11 || type === 24 || type === 27 || type === 37 || type === 38))
      .map(o => ({ name: o.name, object: o.object }))
      : filterBy === 'drivers' ? objList
        .filter(({ info }) => info.idChofer)
        .map(o => ({ name: o.name, object: o.object }))
        : filterBy === 'temp' ? objList
          .filter(({ info }) => info.coldSensor)
          .map(o => ({ name: o.name, object: o.object }))
          : filterBy === 'fuelLevel' ? objList
            .filter(({ info }) => (info.fuelLevel))
            .map(o => ({ name: o.name, object: o.object }))
            : filterBy === 'can' ? objList
              .filter(({ info }) => info.can)
              .map(o => ({ name: o.name, object: o.object }))
              : filterBy === 'engTemp' ? objList
                .filter(({ info }) => info.engineTemp)
                .map(o => ({ name: o.name, object: o.object }))
                : filterBy ? objList
                  .filter(o => o[filterBy])
                  .map(o => ({ name: o.name, object: o.object }))
                  : objList

export default {
  data: () => ({ filtered: null }),
  computed: {
    objectList () { return this.$store.state.data.objectsDataList || [] },
    options () { return this.filtered || this.objectList.slice(0, 200) }
  },
  props: {
    value: null,
    filterBy: {
      type: String,
      default: null,
      required: false
    }
  },
  methods: {
    onQuery (query) {
      if (query !== '') {
        const q = query.toLowerCase().trim()
        const objList = filterByType(this.objectList, this.filterBy)
        this.filtered = q ? filter(q, objList).slice(0, 200) : null
      }
    },
    onClear () {
      this.value = null
      this.filtered = filterByType(this.objectList, this.filterBy)
      this.$emit('on-clear')
    },
    onChange (v) {
      if (!v) {
        this.filtered = filterByType(this.objectList, this.filterBy)
      }
      this.$emit('input', v)
    }
  },
  watch: {
    filterBy (value) {
      if (value) {
        this.filtered = filterByType(this.objectList, this.filterBy)
      } else this.filtered = null
    }
  }
}
</script>
