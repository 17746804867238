import { pick, hasOtherProp, only } from './util'

export const mapObjs = obj => obj.dvcType === 'BCE' ? mapBCEObj(obj) : mapTRAXObj(obj)

const pickGps = obj => obj && only(['date', 'lng', 'lat', 'speed', 'heading', 'gps_age', 't_elapsed', 'dir'], obj)
const pickGsm = gsm => only(['date', 'gsm_lvl'], gsm)
const pickCan = obj => {
  const can = only(['date', 'can_taho', 'can_mileage', 'eng_temp', 'can_fls'], obj)
  return hasOtherProp(['date'], can) ? { can } : null
}
const pickOdom = odom => only(['date', 'odom'], odom)
const pickBatts = batts => only(['date', 'bat1', 'bat2'], batts)
const pickEngineHs = obj => {
  const engineHs = only(['date', 'eng1_hs', 'eng1_ss', 'eng2_hs', 'eng2_ss'], obj)
  return hasOtherProp(['date'], engineHs) ? { engineHs } : null
}
const pickDallas = obj => {
  const dallas = only(['date', 'dallas_id', 'temp', 'humidity'], obj)
  return hasOtherProp(['date'], dallas) ? { dallas: dallas } : null
}

const pickDallasTemp = obj => {
  const dallas = only(['date', 'temp'], obj)
  return dallas && dallas.temp ? { temp: dallas } : null
}

export const mapObjsData = ({ object, co, company, info, config }) => ({
  object, co, company, info, config, name: config.name
})

const mapTRAXObj = ({ object, name, plate, type, co, company, last_move: lastMove, gps, odom, batts, fuel, engine_hs: engineHs, temp, eng_prot: engTemp, fuel_level: fuelLevel, dvcType, typeLabel, brand, model, speedLimit, speedLimitDt, consPro, odomTablero }) =>
  ({
    object,
    ...(brand ? { brand } : null),
    ...(model ? { model } : null),
    ...(name ? { name } : null),
    ...(plate ? { plate } : null),
    ...(type ? { type } : null),
    ...(co ? { co } : null),
    ...(company ? { company } : null),
    ...(lastMove ? { lastMove } : null),
    ...(gps ? { gps: pickGps(gps) } : null),
    ...(odom ? { odom: pickOdom(odom) } : null),
    ...(batts ? { batts: pickBatts(batts) } : null),
    ...(fuel ? { fuel } : null),
    ...pickEngineHs(engineHs),
    ...(temp ? { temp } : null),
    ...(engTemp ? { engTemp } : null),
    ...(fuelLevel ? { fuelLevel } : null),
    dvcType,
    ...(typeLabel ? { typeLabel } : null),
    ...(speedLimit ? { speedLimit } : null),
    ...(speedLimitDt ? { speedLimitDt } : null),
    ...(odomTablero ? { odomTablero } : null),
    ...(gps && gps.engOn !== undefined ? { engOn: gps.engOn } : null),
    ...(consPro ? { consPro } : null)
  })

const mapBCEObj = ({ object, name, plate, type, co, company, last_move: lastMove, gen: { date, pwr_sup: pwrSup, engOn }, gps, adc, gsm, can, dallas, dvcType, typeLabel, brand, model, consPro, speedLimit, odomTablero, canConfig }) =>
  ({
    object,
    ...(brand ? { brand } : null),
    ...(model ? { model } : null),
    date, // last report date
    pwrSup, // last report pwr supply voltage
    ...(name ? { name } : null),
    ...(plate ? { plate } : null),
    ...(type ? { type } : null),
    ...(co ? { co } : null),
    ...(company ? { company } : null),
    ...(adc ? { adc } : null),
    ...(pickGps(gps) ? { gps: pickGps(gps) } : null),
    ...(pick(gsm) ? { gsm: pickGsm(gsm) } : null),
    ...(lastMove ? { lastMove } : null),
    ...pickCan(can),
    ...pickDallas(dallas),
    ...pickDallasTemp(dallas),
    dvcType,
    ...(odomTablero ? { odomTablero } : null),
    ...(typeLabel ? { typeLabel } : null),
    ...(speedLimit ? { speedLimit } : null),
    ...(engOn !== undefined ? { engOn } : null),
    ...(consPro ? { consPro } : null),
    ...(canConfig ? { canConfig } : null)
  })
