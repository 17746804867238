<template>
  <div>
    <iTooltip content="Dibujar Punto" placement="bottom" v-if="!isCreating && !isEditing && !viewed">
      <iButton @click="setDrawMode('Circle', 1)" type="primary" icon="md-pin"/>
    </iTooltip>
    <iTooltip content="Dibujar Poligono" placement="bottom" v-if="!isCreating && !isEditing && !viewed">
      <iButton @click="setDrawMode('Polygon', 0)" type="primary" icon="md-square-outline"/>
    </iTooltip>
    <iForm ref="formInline" :model="formInline" :rules="ruleInline" inline>
      <iFormItem prop="drawingName">
        <iInput
          v-if="viewed || isCreating"
          :disabled="!isCreating && !isEditing"
          type="text"
          v-model="formInline.drawingName"
          placeholder="Ingrese un nombre"
          maxlength="30"
        ></iInput>
      </iFormItem>
    </iForm>
    <iTooltip content="Editar Referencia" placement="bottom" v-if="viewed && !isCreating && !isEditing">
      <iButton
        @click="startEdit"
        type="primary"
        icon="md-create"
      />
    </iTooltip>
    <colorPicker
      v-if="isCreating || isEditing"
      @on-change="setDrawColor"
      v-model="drawColor"
      placement="bottom"
      :colors="colors"
    />
    <iTooltip content="Crear Referencia" placement="bottom" v-if="isCreating">
      <iButton @click="createReference()" type="success" icon="md-checkmark" :loading="loading"/>
    </iTooltip>
    <iTooltip content="Enviar cambios" placement="bottom" v-if="isEditing">
      <iButton @click="updateReference()" type="success" icon="md-cloud-upload" :loading="loading"/>
    </iTooltip>
    <iTooltip content="Eliminar Referencia" placement="bottom" v-if="viewed && !isEditing && !isCreating">
      <iButton @click="deleteModal = true" type="error" icon="md-trash" />
    </iTooltip>
    <iTooltip content="Descartar Cambios" placement="bottom" :disabled="nameTooltip">
      <iButton @click="cancelEdit" type="error" icon="md-undo" v-if="isEditing" :loading="loading"/>
    </iTooltip>
    <iTooltip content="Cancelar" placement="bottom" :disabled="nameTooltip">
      <iButton @click="clearDrawing()" type="error" icon="md-close" v-if="isCreating" :loading="loading"/>
    </iTooltip>
    <iModal v-model="deleteModal" title="Eliminar referencia" @on-ok="deleteReference">
      <p v-if="viewed && !isEditing">
        ¿Esta seguro que desea eliminar la referencia
        <b>{{viewed.name}}</b>?
      </p>
    </iModal>
  </div>
</template>

<script>
import ColorPicker from '@/components/colorPicker'

export default {
  data () {
    return {
      drawType: null,
      colors: ['hsl(14,  100%, 53%)', 'hsl(48,  100%, 67%)', 'hsl(141, 71%,  48%)', 'hsl(171, 100%, 41%)', 'hsl(204, 86%,  53%)', 'hsl(217, 71%,  53%)', 'hsl(271, 100%, 71%)', 'hsl(348, 100%, 61%)'],
      nameTooltip: false,
      deleteModal: false,
      isEditing: false,
      isCreating: false,
      loading: false,
      drawColor: '#209CEE',
      formInline: {
        drawingName: null
      },
      ruleInline: {
        drawingName: [
          { required: true, message: 'Ingrese un nombre para la referencia', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    ColorPicker
  },
  props: {
    map: Object
  },
  computed: {
    references () { return this.$store.state.data.references },
    viewed () { return this.$store.getters['referencesView/getViewed'] }
  },
  methods: {
    startEdit () {
      this.isEditing = true
      this.map.startEditDrawing()
    },
    cancelEdit () {
      this.isEditing = false
      this.map.setDrawing({ ...this.viewed })
      this.map.cancelEditDrawing()
    },
    setDrawColor (color) {
      this.map && this.map.setDrawColor(color)
    },
    setDrawMode (mode, type) {
      this.formInline.drawingName = ''
      this.drawColor = '#209CEE'
      this.isCreating = true
      this.map.setDrawMode(mode)
      this.drawType = type
    },
    clearDrawing () {
      this.isCreating = false
      this.map.clearDrawing()
    },
    createReference () {
      this.$refs.formInline.validate((valid) => {
        if (valid) {
          this.loading = true
          const drawing = this.map.getDrawing()

          const reference = {
            name: this.formInline.drawingName,
            geom: this.drawType === 1 ? {
              type: 'Point',
              coordinates: [drawing.getCenter().x, drawing.getCenter().y]
            } : drawing.toGeoJSONGeometry(),
            type: this.drawType,
            color: this.drawColor.substring(1),
            radius: this.drawType === 1 ? drawing.getRadius() : 0
          }
          this.$store.dispatch('referencesView/createRef', reference).then(() => {
            this.$store.dispatch('data/fetchGeofences')
            this.map.updateReferences(this.references)
            this.map.clearDrawing()
            this.isCreating = false
            this.$Notice.success({
              title: 'Referencia Creada'
            })
            this.loading = false
          },
          () => {
            this.$Notice.error({
              title: 'Error al crear referencia'
            })
            this.loading = false
          })
        }
      })
    },
    updateReference () {
      this.$refs.formInline.validate((valid) => {
        if (valid) {
          this.loading = true

          const drawing = this.map.getDrawing()

          const reference = {
            id: this.viewed.id,
            name: this.formInline.drawingName,
            geom: this.viewed.type === 1 ? {
              type: 'Point',
              coordinates: [drawing.getCenter().x, drawing.getCenter().y]
            } : drawing.toGeoJSONGeometry(),
            type: this.viewed.type,
            color: this.drawColor.substring(1),
            radius: this.viewed.type === 1 ? drawing.getRadius() : 0
          }
          this.$store.dispatch('referencesView/updateRef', { reference, id: this.$store.state.referencesView.viewed }).then((r) => {
            this.$store.dispatch('data/fetchGeofences')
            this.$store.dispatch('referencesView/setViewed', null)
            this.map.clearDrawing()
            this.isEditing = false
            this.$Notice.success({
              title: 'Referencia Editada'
            })
            this.loading = false
          },
          (e) => {
            this.$Notice.error({
              title: 'Error al editar referencia'
            })
            this.loading = false
          })
        }
      })
    },
    deleteReference () {
      this.$store.dispatch('referencesView/deleteRef', this.$store.state.referencesView.viewed).then((r) => {
        this.$store.dispatch('referencesView/setViewed', null)
        this.$store.dispatch('data/fetchGeofences')
        this.$Notice.success({
          title: 'Referencia Eliminada'
        })
      },
      () => {
        this.$Notice.error({
          title: 'Error al eliminar referencia'
        })
      })
    }
  },
  watch: {
    viewed (v) {
      if (v) {
        this.formInline.drawingName = v.name
        this.drawColor = '#' + v.color
      }
    }
  }
}
</script>
