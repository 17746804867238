<template>
  <iLayout>
    <app-header/>
    <iContent class="referencesLayoutContent">
      <iSider width="240">
        <referencesTree class="treeContainer"/>
      </iSider>
      <div class="referencesMap" ref="map"/>
      <ReferencesMapToolbar :map="map" class="referencesMapToolbar"/>
    </iContent>
  </iLayout>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import ReferencesMap from '@/maps/referencesMap'
import ReferencesTree from '@/components/references/ReferencesTree'
import ReferencesMapToolbar from '@/components/references/ReferencesMapToolbar'

export default {
  data () {
    return {
      map: {}
    }
  },
  components: {
    AppHeader,
    ReferencesTree,
    ReferencesMapToolbar
  },
  computed: {
    references () { return this.$store.state.data.references },
    objects () { return this.$store.state.data.objects },
    viewed () { return this.$store.getters['referencesView/getViewed'] },
    homeMapExtent () { return this.$store.state.homeView.mapExtent }
  },
  mounted () {
    this.map = ReferencesMap(this.$refs.map, this.references, this.objects, this.$store)
    this.map.onSelect(id => { this.$store.dispatch('referencesView/setViewed', id) })
    if (this.homeMapExtent) this.map.map.fitExtent(this.homeMapExtent)
  },
  watch: {
    references (refs) { this.map && this.map.updateReferences(refs) },
    viewed (viewed, old) {
      if (old) this.map.unsetDrawing(old)
      if (viewed) this.map.setDrawing({ ...viewed })
    }
  },
  created () { this.$store.dispatch('startUpdate') },
  beforeDestroy () { this.$store.dispatch('stopUpdate') },
  destroyed () { this.map && this.map.destroy() }
}
</script>
