import { createMarker, createLabelMarker, createRefMarker, createRefCircle } from '@/maps/utils/homeMapUtils'
import { VectorLayer } from 'maptalks'
import { ClusterLayer } from 'maptalks.markercluster'
import { mapO } from '@/util/util'

export const createGeometries = (objects, references, refPointsRadius, onClick) => {
  const markers = mapO(createMarker(onClick))(objects)
  const labelMarkers = mapO(createLabelMarker())(objects)

  const refsMarkers = mapO(createRefMarker(onClick))(references)
  const refsMarkersPointsRadius = mapO(createRefCircle())(refPointsRadius)

  return { markers, labelMarkers, refsMarkers, refsMarkersPointsRadius }
}

export const createVectorLayers = (markers, labelMarkers, refsMarkers, refsMarkersPointsRadius, noClusters) => {
  const referencesCluster = new VectorLayer('Referencias', [], {
    geometryEvents: false
  })
  const refsPointsRadiusLayer = new VectorLayer('Radio Puntos', [], {
    geometryEvents: false
  })

  const objectsCluster = new ClusterLayer('Unidades', [], {
    maxClusterZoom: noClusters ? 5 : 12,
    textSymbol: {
      textFaceName: '"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"',
      textSize: 18,
      textDy: 2,
      textWeight: 'bold'
    }
  })
  const labelsCluster = new ClusterLayer('Labels', [], {
    symbol: {
      markerType: 'ellipse',
      markerFillOpacity: 0,
      markerLineOpacity: 0
    },
    maxClusterZoom: noClusters ? 5 : 12,
    geometryEvents: false,
    drawClusterText: false
  })

  objectsCluster.addGeometry(Object.values(markers))
  labelsCluster.addGeometry(Object.values(labelMarkers))
  referencesCluster.addGeometry(Object.values(refsMarkers))
  refsPointsRadiusLayer.addGeometry(Object.values(refsMarkersPointsRadius))

  return {
    refsPointsRadiusLayer,
    referencesCluster,
    labelsCluster,
    objectsCluster
  }
}
