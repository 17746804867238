import { Map, GroupTileLayer } from 'maptalks'
import createTileLayers from './tileLayers'
import LayerSwitchControl from './layerSwitchControl'
import Zoom from './zoomControl'

const createMap = ($el, layers, excludeLayers) => {
  const tileLayers = createTileLayers()
  const map = new Map($el, {
    center: [-65, -34],
    zoom: 6,
    zoomAnimation: false,
    panAnimation: false,
    fpsOnInteracting: 0,
    dragRotate: false,
    dragPitch: false,
    attribution: false,
    touchGesture: false,
    touchZoom: false,
    touchRotate: false,
    touchPitch: false,
    baseLayer: new GroupTileLayer('Base TileLayer', tileLayers),
    layers: layers,
    minZoom: 6,
    maxZoom: 19,
    scaleControl: {
      position: 'top-left',
      metric: true,
      containerClass: 'mapScaleControl'
    },
    viewHistory: false
  })

  const destroy = () => { map.remove() }

  const layerSwitcher = new LayerSwitchControl({
    baseTitle: 'Mapas',
    position: { top: 10, right: 10 },
    excludeLayers: excludeLayers
  })

  layerSwitcher.addTo(map)

  const zoomControl = new Zoom({
    position: { bottom: 10, left: 10 }
  })
  zoomControl.addTo(map)

  return { map, destroy }
}

export default createMap
