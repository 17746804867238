export function animate (duration, update) {
  let start = null
  let elapsed = 0
  let progress = 0
  function step (time) {
    if (!start) start = time
    elapsed = time - start
    progress = elapsed / duration
    if (progress > 1) progress = 1
    if (progress < 1) requestAnimationFrame(step)
    update(progress)
  }
  requestAnimationFrame(step)
}

export const anim = (duration, update, start) => {
  function step (time) {
    if (!start) start = time
    const progress = (time - start) / duration
    if (progress < 1) requestAnimationFrame(step)
    update(progress < 1 ? progress : 1)
  }
  requestAnimationFrame(step)
}
