import * as maptalks from 'maptalks'
import { mapOb, mapRef, mapRefsPoints } from '@/maps/utils/homeMapUtils'
import { toObjBy } from '@/util/util'

const filterRefsPoints = r => { if (r.type === 1) return r }

const withGps = o => o && o.gps

const toObj = toObjBy('object')
export const fObjs = objs => toObj(Object.values(objs).filter(withGps).map(mapOb))
export const fRefs = refs => toObjBy('id')(Object.values(refs).map(mapRef))
export const fRefsRadius = refs => toObjBy('id')(Object.values(refs).filter(filterRefsPoints).map(mapRefsPoints))

const baseTextSymbol = {
  textFaceName: '"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"',
  textWeight: 'bold',
  textStyle: 'normal',
  textSize: 12,
  textOpacity: 1,
  textHaloRadius: 1,
  textHorizontalAlignment: 'middle',
  textVerticalAlignment: 'middle',
  textAlign: 'center'
}

export const textSymbol = {
  ...baseTextSymbol,
  textFill: '#fff',
  textHaloFill: 'hsl(0, 0%, 7%)'
}

export const invTextSymbol = {
  ...baseTextSymbol,
  textHaloFill: '#fff',
  textFill: 'hsl(0, 0%, 7%)'
}

export function getOffset (st, e) {
  const start = new maptalks.Coordinate(st)
  const end = new maptalks.Coordinate(e)
  return end.sub(start)
}

export const createLabelSymbol = obj => {
  const marker = Object.assign(
    {},
    {
      ...textSymbol,
      textHaloRadius: 1.5,
      textDy: 20,
      textVerticalAlignment: 'bottom'
    },
    {
      textName: obj.name
    }
  )

  return marker
}

export const mapAnimateTo = (map, value) => {
  map.animateTo(
    {
      zoom: 17,
      center: [value.gps.lng, value.gps.lat]
    },
    { duration: 1 }
  )
}
