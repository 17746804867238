import { VectorLayer } from 'maptalks'
import { getOffset } from './utils/mapUtils'
import BaseMap from './baseMap'
import { withGps, mapOb, createMarker } from './utils/seguimientosUtils'
import { createSymbol } from '@/maps/vehicle'

const SeguimientosMap = ($el) => {
  let object = null
  let selObjHandler = null
  let marker = null

  let segLayer = new VectorLayer('segLayer', [], {
    maxClusterZoom: 11
  })

  const { map, destroy } = BaseMap($el, [], [])

  map.on('click', () => selObjHandler && selObjHandler())

  const update = obj => {
    if (!withGps(obj)) return
    obj = mapOb(obj)
    if (!object || obj.object !== object.object) {
      segLayer = null
      const m = createMarker(obj)
      marker = m
      segLayer = new VectorLayer('segLayer', [m], {
        maxClusterZoom: 11
      })
      segLayer.addTo(map)
    } else {
      updateMarker(marker, obj, object)
    }
    object = obj
  }

  const animateTo = value => {
    map.animateTo(
      {
        // zoom: 15,
        center: [value.gps.lng, value.gps.lat]
      },
      {
        duration: 2000
      }
    )
  }

  const onSelect = cb => { selObjHandler = cb }

  const clearMap = () => {
    marker = null
    segLayer.clear()
    segLayer.remove()
    segLayer = null
  }

  if (object) {
    marker = createMarker(object)
    segLayer.addGeometry(marker)
  }

  return { map, onSelect, update, destroy, animateTo, clearMap }
}

const updateMarker = (marker, obj, old) => {
  if (obj.date <= old.date) return

  const offset = getOffset(marker.getCoordinates(), [obj.lng, obj.lat])
  if (offset.x === 0 && offset.y === 0) return

  if (marker._animPlayer) {
    marker._animPlayer.finish()
  }

  marker.animate(
    { translate: offset },
    { duration: 2e3 },
    frame => {
      if (frame.state.playState === 'finished') {
        marker
          .setCoordinates([obj.lng, obj.lat])
          .updateSymbol(createSymbol(obj))
      }
    }
  )
}

export default SeguimientosMap
