<template>
  <div class="scrollable">
    <div class="scrollable__body" ref="body" @scroll="updateVisibleItems">
      <div class="scrollable__scroll" :style="styleScroll">
        <iTimeline>
          <template v-for="row in scrolledRows">
            <slot v-bind="row" />
          </template>
        </iTimeline>
      </div>
    </div>
  </div>
</template>

<script>
import { anim } from '@/util/animate'

export default {
  data: () => ({
    selected: null,
    paddingTop: 0,
    startIndex: 0,
    endIndex: 0
  }),
  props: {
    height: { type: Number, required: true },
    rows: { type: Array, requires: true },
    value: null
  },
  computed: {
    heightScroll () { return this.rows.length * this.height },
    scrolledRows () { return this.rows.slice(this.startIndex, this.endIndex) },
    styleScroll () {
      return {
        height: this.heightScroll + 'px',
        paddingTop: this.paddingTop + 'px'
      }
    }
  },
  mounted () {
    this.updateVisibleItems()
    this.updateSelected(this.value)
  },
  methods: {
    updateVisibleItems () {
      const $body = this.$refs.body
      const start = ($body.scrollTop / this.height | 0) - 3
      this.startIndex = (start > 0) ? start : 0
      const end = (($body.scrollTop + $body.clientHeight) / this.height | 0) + 3
      this.endIndex = (end >= this.rows.length) ? this.rows.length : end
      this.paddingTop = this.startIndex * this.height
    },
    updateSelected (value) {
      this.selected = value
      if (value == null) return
      const $scroll = this.$refs.body
      const scrollStart = $scroll.scrollTop
      const scrollEnd = value * this.height - ($scroll.clientHeight - this.height) / 2
      $scroll.scrollTop = scrollEnd
      const delta = scrollEnd - scrollStart
      anim(400, p => {
        $scroll.scrollTop = scrollStart + p * delta
      })
    }
  },
  watch: {
    rows () {
      this.updateVisibleItems()
    },
    value (value) {
      this.updateSelected(value)
    }
  }
}
</script>

<style>
.scrollable {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  width: 100%;
  max-height: 100%;
}

.scrollable__body {
  position: relative;
  flex: 1 0 240px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
