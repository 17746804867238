import { sync } from 'vuex-router-sync'
import { getMe } from '@/api'

const ls = window.localStorage

const MODULE_NAME = 'login'
const LOGIN_PATH = '/login'
const API_PATH = '/auth'
const LOCAL_KEY = 'td_user'

const initialState = () => ({
  user: null,
  token: null,
  type: 0,
  error: null
})

const getJSON = str => {
  try { return JSON.parse(str) } catch (e) { return null }
}
const mutations = {
  SET_USER: (state, { user, token, type, error = null }) =>
    Object.assign(state, { user, token, type, error }),
  SET_USER_DATA: (state, user) => Object.assign(state, { user }),
  RESET: state => Object.assign(state, initialState()),
  SET_ERROR: (state, error) => Object.assign(state, initialState(), { error })
}
/**
 * Authorization plugin
 * @param {VueRouter} router
 * @param {AxiosInstance} axios
 */
export default function authPlugin (router, axios) {
  /**
   * @param {Store} store
   */
  return function auth (store) {
    sync(store, router)
    const { setJWT, delJWT, hasJWT } = axiosHelper(axios)
    const toLogin = () => router.push(LOGIN_PATH)
    // Actions
    const init = ({ commit, dispatch }) => {
      const user = getJSON(ls.getItem(LOCAL_KEY))
      if (user) {
        setJWT(user.token)
        commit('SET_USER', user)
        getMe().then(({ data }) => commit('SET_USER_DATA', data))
      } else toLogin()
    }
    const login = ({ commit, dispatch }, creds) =>
      axios.post(API_PATH, creds).then(res => {
        const { name: user, token, type } = res.data
        setJWT(token)
        ls.setItem(LOCAL_KEY, JSON.stringify({ user, token, type }))
        commit('SET_USER', { user, token, type })
        getMe().then(({ data }) => commit('SET_USER_DATA', data))
        router.push('/')
      }).catch(error => {
        console.log(error.response.data.uiMsg)
        commit('SET_ERROR', error)
      })
    const logout = ({ commit, dispatch }) => {
      delJWT()
      ls.clear()
      commit('RESET')
      toLogin()
    }
    // Register vuex module
    store.registerModule(MODULE_NAME, {
      namespaced: true,
      state: initialState,
      mutations,
      actions: { init, login, logout }
    })

    router.beforeEach((to, from, next) => {
      const isAuth = hasJWT()
      if (to.path === LOGIN_PATH) next(isAuth ? '/' : undefined)
      else next(isAuth ? undefined : LOGIN_PATH)
    })
    store.dispatch(`${MODULE_NAME}/init`)
  }
}

function axiosHelper (axios) {
  const { common } = axios.defaults.headers
  return {
    setJWT: token => (common.Authorization = `Bearer ${token}`),
    delJWT: () => delete common.Authorization,
    hasJWT: () => !!common.Authorization
  }
}
