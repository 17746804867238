import { strComp, map, pick, omit, pipe, reduceO, sort, vals } from './util'
import { MIN_WARN_TIME, MIN_WARN_TIME_MOV, MIN_ERROR_TIME } from './constants'

const mapUs = map(pick('object name co company gps type last_move'))

const omitUnits = omit('units')

const sortByName = sort(strComp('name'))

const fComp = u => ({ id: u.co, label: u.company, level: 1, units: [] })

const fUnit = u => ({
  id: u.object,
  label: u.name,
  level: 2,
  type: u.type,
  speed: u.gps ? u.gps.speed : 0,
  warning: u.gps && !!((u.gps.t_elapsed > MIN_WARN_TIME_MOV && u.gps.speed) || (u.gps.t_elapsed > MIN_WARN_TIME) || u.gps.gps_age > 240),
  error: u.gps && (u.gps.t_elapsed > MIN_ERROR_TIME),
  lastMoveDiff: u.last_move ? Date.now() / 1000 - u.last_move : 0
})

const compR = (cos, u) => {
  (cos[u.co] || (cos[u.co] = fComp(u))).units.push(fUnit(u))
  return cos
}

const toComp = pipe(reduceO(compR), vals, sort(strComp('label')))

export const mapToTree = xs => xs.reduce((r, c) => r.concat(omitUnits(c), c.units), [])

export const flatCompanies = pipe(mapUs, sortByName, toComp, mapToTree)

export const companiesTree = pipe(mapUs, sortByName, toComp)
